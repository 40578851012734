import Confirm from '@components/confirmModal/ConfirmModal';
import CustomSlider from '@components/customSlider/CustomSlider';
import styles from '@components/filesWindow/FilesWindow.module.scss';
import MoreHorizontal from '@components/icons/MoreHorizontal';
import LoadingSpinner from '@components/loaders/loadingSpinner/LoadingSpinner';
import PlotLegend from '@components/plotLegend/PlotLegend';
import SetAnomalyContext from '@components/setAnomalyContext/SetAnomalyContext';

import { http } from '@server/http';
import { urls } from '@server/urls';

import { fetchFilesObjectsFx } from '@store/filesObjects/filesObjects';
import { fetchFileGroupsObjectsFx } from '@store/groupsObjects/groupsObjects';
import { $models } from '@store/models/models';

import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Plot from 'react-plotly.js';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as regexpp from 'regexpp';

//@ts-ignore
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    //@ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <MoreHorizontal />
    {children}
  </a>
));

const FileDropdown = ({ file_id }: { file_id: number }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState({});
  const [showFileData, setShowFileData] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<number>();
  const [rangeAnomaly, setRangeAnomaly] = useState([500, 1000]);

  const [selectedFileName, setSelectedFileName] = useState('');
  const [plotWidth, setPlotWidth] = useState(500);
  const [plotMaxX, setPlotMaxX] = useState(500);
  const [selectedModelId, setSelectedModelId] = useState<number>();
  const [showSelectAnomalyRange, setShowSelectAnomalyRange] = useState(false);
  const [anomalyMinScreenX, setAnomalyMinScreenX] = useState(500);
  const [isEditAnomaly, setIsEditAnomaly] = useState(false);
  const [selectedAnomalyData, setSelectedAnomalyData] = useState(Object);
  const [curtainsColor, setCurtainsColor] = useState('#0094FB');

  const modelOptions = useStore($models);

  const handleChange = (event: Event, newValue: number | number[]) => {
    // @ts-ignore
    if (event.target.value[0] !== rangeAnomaly[0]) {
      // @ts-ignore
      setAnomalyMinScreenX(event.clientX);
    }
    setRangeAnomaly(newValue as number[]);
  };

  const setAnomaly = async (indexM: number, anomalyType: string) => {
    if (isEditAnomaly) {
      delAnomaly(file_id, [selectedAnomalyData.x[0], selectedAnomalyData.x[2]]);
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    let color = 'green';
    if (Number(indexM) > 10 && Number(indexM) < 21) {
      color = 'yellow';
    } else if (Number(indexM) > 21) {
      color = 'red';
    }

    let x0 = rangeAnomaly[0];
    let x1 = rangeAnomaly[1];
    // @ts-ignore
    let y = selectedFileData.data.filter((item: any) => item.name === 'Объединненый сигнал')[0].y;
    let y0 = Math.min(...y);
    let y1 = Math.max(...y);
    console.log(y0, y1);

    let insert = {
      fill: 'toself',
      fillcolor: color,
      legendgroup: 'неучтенная',
      line: {
        color: color,
        width: 0.1,
      },
      mode: 'lines',
      name: 'неучтенная',
      opacity: 0.3,
      showlegend: false,
      text: '',
      textposition: 'bottom center',
      x: [x0, x0, x1, x1, x0],
      y: [y0, y1, y1, y0, y0],
      type: 'scatter',
    };

    try {
      let body = {
        file_id: file_id,
        range: rangeAnomaly,
        type: anomalyType,
        index_m: indexM,
      };

      await http.post(urls.setAnomaly(), body).then((res) => {
        if (res.status === 204) {
          toast.success('Аномалия выбрана');
          // @ts-ignore
          setSelectedFileData({ ...selectedFileData, data: [...selectedFileData.data, insert] });
        } else {
          toast.error('Не удалось выбрать  аномалию');
        }
      });
    } catch {
      toast.error('Не удалось выбрать аномалию');
    }
    setShowSelectAnomalyRange(false);
    setIsEditAnomaly(false);
    setCurtainsColor('#0094FB');
  };

  const selectAnomalyPlot = async (e: any) => {
    if (e.points[0].data.fill !== undefined && !isEditAnomaly) {
      setIsEditAnomaly(true);
      // @ts-ignore
      const anomalyData = selectedFileData.data.filter(
        (item: any) => item.x[0] === e.points[0].data.x[0],
      )[0];
      console.log('anomalyData', anomalyData);
      let type_regex = anomalyData.text.match(/type: \w+/g);
      // console.log(regexpp.parse(anomalyData.text)
      let mySubString = anomalyData.text.substring(
        anomalyData.text.indexOf('Тип аномалии:') + 'Тип аномалии:'.length,
        anomalyData.text.indexOf(
          '<br>',
          anomalyData.text.indexOf('Тип аномалии:') + 'Тип аномалии:'.length,
        ),
      );
      console.log('mySubString', mySubString);
      setCurtainsColor(anomalyData.line.color);
      console.log(anomalyData.name);
      console.log(anomalyData.name === '[index [1,11] ');
      setSelectedAnomalyData(anomalyData);

      setSelectedFileData({
        ...selectedFileData,
        // @ts-ignore
        data: selectedFileData.data.filter((item: any) => item.x[0] !== e.points[0].data.x[0]),
      });
      setTimeout(function () {
        setRangeAnomaly([e.points[0].data.x[0], e.points[0].data.x[2]]);
        setShowSelectAnomalyRange(true);
        setAnomalyMinScreenX(
          (e.points[0].data.x[0] / plotMaxX) * Number(plotWidth) +
            e.event.target.getBoundingClientRect().x -
            4,
        );
      }, 200);
      setShowSelectAnomalyRange(false);
    }
  };

  const applyModel = async () => {
    setSelectedFileData({});

    let body = {
      file_id: selectedFileId,
      ml_model_id: selectedModelId,
    };

    let historyId = 0;
    try {
      await http.post(urls.setAnomalyAuto(), body).then((res) => {
        if (res.status === 202 && res.data.id !== undefined) {
          toast.success('Применение модели ...');
          historyId = res.data.id;
        } else {
          toast.error('Не удалось применить модель');
        }
      });
    } catch {
      toast.error('Не удалось применить модель');
    }

    let history_status = 'pending';
    while (history_status === 'pending') {
      try {
        await http.get(urls.history(historyId)).then((res) => {
          if (res.data.status === 'success') {
            toast.success('Применение модели завершено');
            loadFileData(selectedFileId!);
            history_status = res.data.status;
          } else if (res.data.status === 'fail') {
            toast.error('Ошибка при применении модели');
            toast.error(res.data.detail);
            loadFileData(selectedFileId!);
            history_status = res.data.status;
          }
          console.log('SSSS', res.data.status);
        });
      } catch {
        history_status = 'pending';
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  const delAnomaly = async (file_id: number, range: Array<number>) => {
    let body = {
      file_id: file_id,
      range: range,
    };
    try {
      await http.post(urls.deleteAnomaly(), body).then((res) => {
        if (res.status === 204) {
          toast.success('Участок с аномалией удален');
          setSelectedFileData({
            ...selectedFileData,
            // @ts-ignore
            data: selectedFileData.data.filter((item: any) => item.x[0] !== rangeAnomaly[0]),
          });
        } else {
          toast.error('Не удалось удалить участок с аномалиией');
        }
      });
    } catch (e) {
      console.log(e);
      toast.error('Не удалось удалить участок с аномалиией');
    }
    setRangeAnomaly([500, 1000]);
    setAnomalyMinScreenX((500 / plotMaxX) * plotWidth + 110);
    setShowSelectAnomalyRange(false);
    setIsEditAnomaly(false);
    setCurtainsColor('#0094FB');
  };

  const loadFileData = async (file_id: number) => {
    setSelectedFileId(file_id);
    setSelectedFileData({});
    setShowFileData(true);
    try {
      const res = await http.get(urls.filePlotData(file_id));
      setSelectedFileData(JSON.parse(res.data));
    } catch (e: any) {
      toast.error(e.message);
      setShowFileData(false);
    }
  };

  const deleteFile = async (id: number) => {
    try {
      await http.delete(urls.file(id), {}).then((res) => {
        fetchFileGroupsObjectsFx();
        fetchFilesObjectsFx();
        toast.success('Файл удален');
      });
    } catch (e: any) {
      toast.error('Возникла ошибка при удалении файла');
      toast.error(e.response.data.detail);
    }
    setCurtainsColor('#0094FB');
  };

  const closeContext = async () => {
    setShowSelectAnomalyRange(false);
    if (isEditAnomaly) {
      setSelectedFileData({
        ...selectedFileData,
        // @ts-ignore
        data: [...selectedFileData.data, selectedAnomalyData],
      });
      setIsEditAnomaly(false);
    }
    setCurtainsColor('#0094FB');
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => loadFileData(file_id)}>Разметка</Dropdown.Item>
        <Dropdown.Item onClick={() => setShowConfirm(true)}>Удалить файл</Dropdown.Item>
      </Dropdown.Menu>
      <Confirm
        onClick={() => deleteFile(file_id)}
        show={showConfirm}
        text={'Удалить файл?'}
        onClose={() => setShowConfirm(false)}
      />

      <Modal size="lg" fullscreen={true} show={showFileData} onHide={() => setShowFileData(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedFileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: 'white' }}>
          {
            // @ts-ignore
            selectedFileData.data === undefined ? (
              <LoadingSpinner />
            ) : (
              <div>
                <Plot
                  style={{ width: '100%' }}
                  className={'ms-2'}
                  // @ts-ignore
                  data={selectedFileData.data}
                  // @ts-ignore
                  layout={selectedFileData.layout}
                  onClick={selectAnomalyPlot}
                  onInitialized={(figure, graphDiv) => {
                    console.log('onInitialized');
                    console.log(figure);
                    console.log(graphDiv);
                    // @ts-ignore
                    let width = graphDiv.querySelectorAll(['rect.nsewdrag'])[0].attributes.width
                      .value;
                    setPlotWidth(Number(width));
                    // @ts-ignore
                    setPlotMaxX(figure!.layout!.xaxis!.range[1]!);
                    // Не работает setState с первого раза, поэтому обновляем selectedFileData
                    // @ts-ignore
                    setSelectedFileData({ ...selectedFileData, data: [...selectedFileData.data] });
                  }}
                  onUpdate={() => console.log('onUpdate')}
                />

                <PlotLegend className={'ms-5 mb-5'} />

                <div className={'row col-12'}>
                  <div className={'col-12'}>
                    <div className={'row ms-2'}>
                      <Button
                        disabled={showSelectAnomalyRange}
                        onClick={() => {
                          setIsEditAnomaly(false);
                          setShowSelectAnomalyRange(true);
                          setRangeAnomaly([500, 1000]);
                          setAnomalyMinScreenX((500 / plotMaxX) * plotWidth + 110);
                        }}
                        className={styles.mainButton + ' ms-5 col-2'}
                      >
                        Отметить аномалию
                      </Button>
                      <p className={'col-4 ms-2 mt-1 text-muted'}>
                        {' '}
                        *Для удаления / редактирования аномалии кликните по участку на графике
                      </p>
                    </div>

                    <div
                      hidden={!showSelectAnomalyRange}
                      style={{
                        marginLeft: '76px',
                        width: `${plotWidth + 15}px`,
                        top: '368px',
                        position: 'absolute',
                        zIndex: 1000,
                      }}
                      className={'row'}
                    >
                      <CustomSlider
                        value={rangeAnomaly}
                        backgroundColor={curtainsColor}
                        onChange={handleChange}
                        // @ts-ignore
                        min={
                          // @ts-ignore
                          selectedFileData.layout.xaxis !== undefined
                            ? // @ts-ignore
                              selectedFileData.layout.xaxis!.range![0]
                            : 0
                        }
                        // @ts-ignore
                        max={
                          // @ts-ignore
                          selectedFileData.layout.xaxis !== undefined
                            ? // @ts-ignore
                              selectedFileData.layout.xaxis!.range![1]
                            : 5000
                        }
                      />
                      <SetAnomalyContext
                        fileId={file_id}
                        isEdit={isEditAnomaly}
                        delAnomaly={() =>
                          delAnomaly(file_id, [selectedAnomalyData.x[0], selectedAnomalyData.x[2]])
                        }
                        setAnomaly={setAnomaly}
                        xCoord={`${(rangeAnomaly[1] / plotMaxX) * plotWidth}`}
                        closeContext={closeContext}
                        indexMDefault={
                          selectedAnomalyData.name === 'index [1,11]'
                            ? 5
                            : selectedAnomalyData.name === 'index [12,20] '
                            ? 15
                            : 25
                        }
                        anomalyTypeDefault={
                          selectedAnomalyData.text
                            ? selectedAnomalyData.text
                                .substring(
                                  selectedAnomalyData.text.indexOf('Тип аномалии:') +
                                    'Тип аномалии:'.length,
                                  selectedAnomalyData.text.indexOf(
                                    '<br>',
                                    selectedAnomalyData.text.indexOf('Тип аномалии:') +
                                      'Тип аномалии:'.length,
                                  ),
                                )
                                .trim()
                            : 'ЛП'
                        }
                      />
                    </div>
                  </div>

                  <div className={'col-12 ms-5 mt-5'}>
                    <h4 className={styles.mainText + ' pt-0 mt-0'}>
                      Применить модель для разметки аномалий
                    </h4>
                    <p className={styles.mainText + ' pt-0 mt-0 text-muted mb-3'}>
                      *Ранее размеченные аномалии удалятся
                    </p>

                    <div className={'col-3 ms-1'}>
                      Модель
                      <Select
                        isDisabled={showSelectAnomalyRange}
                        options={modelOptions}
                        onChange={(e: any) => setSelectedModelId(Number(e.value))}
                        value={modelOptions.find((m: any) => m.value === selectedModelId)}
                      />
                    </div>

                    <Button
                      disabled={showSelectAnomalyRange}
                      onClick={applyModel}
                      className={styles.mainButton + ' col-2 ms-1 mt-4'}
                    >
                      Применить
                    </Button>
                  </div>
                </div>
              </div>
            )
          }
        </Modal.Body>
      </Modal>
    </Dropdown>
  );
};

export default FileDropdown;
