// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes LoadingSpinner_spinner__ZywkX {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.LoadingSpinner_loadingSpinner__MmhlF {\n  width: 50px;\n  height: 50px;\n  border: 10px solid #f3f3f3; /* Light grey */\n  border-top: 10px solid #383636; /* Black */\n  border-radius: 50%;\n  animation: LoadingSpinner_spinner__ZywkX 1.5s linear infinite;\n  z-index: 999999;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n}\n\n.LoadingSpinner_spinnerContainer__m1kZE {\n  position: fixed;\n  z-index: 999;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  background-color: #ccc;\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/components/loaders/loadingSpinner/LoadingSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,0BAAA,EAAA,eAAA;EACA,8BAAA,EAAA,UAAA;EACA,kBAAA;EACA,6DAAA;EACA,eAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;AAAF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;AAAF","sourcesContent":["@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loadingSpinner {\n  width: 50px;\n  height: 50px;\n  border: 10px solid #f3f3f3; /* Light grey */\n  border-top: 10px solid #383636; /* Black */\n  border-radius: 50%;\n  animation: spinner 1.5s linear infinite;\n  z-index: 999999;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n}\n\n.spinnerContainer {\n  position: fixed;\n  z-index: 999;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  background-color: #ccc;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": "LoadingSpinner_loadingSpinner__MmhlF",
	"spinner": "LoadingSpinner_spinner__ZywkX",
	"spinnerContainer": "LoadingSpinner_spinnerContainer__m1kZE"
};
export default ___CSS_LOADER_EXPORT___;
