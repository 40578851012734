import { http } from '@server/http';
import { urls } from '@server/urls';

// import { logger } from '@lib/class/logger';
// import { CreateJWTProps } from '@lib/types/auth/authTypes';
//
// import { PROJECTS } from '@src/routes/routes';

import { createDomain, createEvent, createStore } from 'effector';
import {API_URL} from "@src/http";

export const LOCAL_TOKEN_KEY = 'token';

const authDomain = createDomain();

export const creatAuthTokenAndSaveLocalFx = authDomain.createEffect(
  async (params: any) => {
      console.log('params', params)

      let body = new URLSearchParams();
      body.append("username", params.username);
      body.append("password", params.password);

      const res = await(await fetch(API_URL + urls.authLogin(), {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: body,
          redirect: 'follow'
      })).json()
      console.log('res', res)
      // return res.access_token


    // const res = await http.post<{ auth_token: string }>(urls.authLogin(), params);
    const token = res.access_token;
    localStorage.setItem(LOCAL_TOKEN_KEY, token);
    console.log('LOCAL_TOKEN_KEY', token)
    return token;
  },
);

export const checkLocalAuth = authDomain.createEffect(() => {
  const token = localStorage.getItem(LOCAL_TOKEN_KEY);
  return !!token;
});

export const logoutAndRemoveLocalToken = authDomain.createEffect(async () => {
  try {
      await http.post(urls.authLogout());
  } catch {
      console.log('token del error')
  }
  localStorage.removeItem(LOCAL_TOKEN_KEY);
});

export const $isAuth = authDomain
  .createStore(false)
  .on(checkLocalAuth.doneData, (_, payload) => payload)
  .on(creatAuthTokenAndSaveLocalFx.doneData, () => true)
  .on(logoutAndRemoveLocalToken.doneData, () => false);

export const setUrlBeforeAuth = createEvent<string>();
export const clearUrlBeforeAuth = createEvent();
export const $urlBeforeAuth = createStore('/')
  .on(setUrlBeforeAuth, (_, payload) => payload)
  .reset(clearUrlBeforeAuth);

$urlBeforeAuth.watch(console.log);
