import React, {useState} from 'react';
import {Button, Card, Image, Modal} from "react-bootstrap";
import styles from "@components/groupsWindow/GroupsWindow.module.scss";
import EditIcon from "@components/icons/EditIcon";
import DeleteIcon from "@components/icons/DeleteIcon";
import Confirm from "@components/confirmModal/ConfirmModal";
import Select from "react-select";
import {http} from "@server/http";
import {urls} from "@server/urls";
import {fetchFileGroupsObjectsFx} from "@store/groupsObjects/groupsObjects";
import {$datasetFiles, fetchFilesObjectsFx} from "@store/filesObjects/filesObjects";
import {toast} from "react-toastify";
import {API_URL} from "@src/http";
import {ModelIcon, FolderIcon} from "@components/icons_img";


const GroupCard = ({groupData, filesOptions}: {groupData: any, filesOptions: any}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [editGroupShow, setEditeGroupShow] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([])

    const deleteFileGroup = async(id: number) => {
        try{
            await http
                .delete(urls.group(id), {})
                .then(res => {
                    fetchFileGroupsObjectsFx();
                    fetchFilesObjectsFx();
                    toast.success('Группа удалена')
                })
        } catch {
            toast.error('Возникла ошибка при удалении группы')
        }
    };

    const editFileGroup = async() => {
        await(await fetch(API_URL + '/api/edit_group/', {
            method: 'POST',
            body: JSON.stringify({"group_name": groupData.title, "files": selectedFiles}),
            headers: {'Content-Type': 'application/json'}
        })).json()
            .then(res => {
                if (res.status === 'ok') {
                    fetchFileGroupsObjectsFx();
                    setEditeGroupShow(false);
                    fetchFilesObjectsFx();
                    toast.success('Изменения сохранены');
                } else {
                    toast.error('Возникла ошибка при сохранении группы');
                }
            })
    };


    return (
        <div className={'col-3 mt-3'} title={`Файлов: ${groupData.files.length}`}>
            <Card
                style={{backgroundColor: '#f2f2f4', height: '70px', cursor: 'pointer'}}
                key={groupData!['label']}
                onClick={() => {setEditeGroupShow(true)}}
            >
                <Card.Body className={'row'}>
                    <div className={'col-2'}>
                        <Image width={'36'} height={'36'} src={FolderIcon} className={'me-4'}/>
                    </div>
                    <h5 className={styles.mainText + ' col-6 mt-1'}>{groupData['title']}</h5>
                </Card.Body>
                <Confirm onClick={() => deleteFileGroup(groupData['id'])} show={showConfirm} text={'Удалить группу?'} onClose={() => setShowConfirm(false)}/>
            </Card>

            <Modal size="lg" show={editGroupShow} onHide={() => setEditeGroupShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.mainText}>Группа {groupData['title']} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className={styles.mainText + ' mt-2'}>Файлы</h5>
                    {/*@ts-ignore*/}
                    <Select onChange={(e: any) => setSelectedFiles(Array.from(e.values()).map(f => f['value']))}
                            closeMenuOnSelect={false}
                            isMulti
                            options={filesOptions}
                            defaultValue={filesOptions.filter((opt: any) => (groupData.files.map((o: any) => o['id'])).includes(opt['value']))}
                    />
                    <Button
                        variant={'danger'}
                        onClick={() => {setShowConfirm(true); setEditeGroupShow(false)}}
                        className={'mt-2 ms-2 float-end'}>
                        Удалить группу
                    </Button>
                    <Button onClick={editFileGroup} className={styles.mainButton + ' mt-2 float-end'}>Сохранить</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GroupCard;
