import React, {useEffect, useState} from 'react';
import styles from './ApplyModel.module.scss';
import {toast} from "react-toastify";
import {Button} from "react-bootstrap";
import {$datasetFiles, fetchDatasetFilesFx} from "@store/filesObjects/filesObjects";
import {useStore} from "effector-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';
import Select, {
    components,
    ControlProps,
    Props,
    StylesConfig,
} from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import FileResult from "@components/fileResult/FileResult";
import {$models, $modelsObjects, fetchModelsFx, fetchModelsObjectsFx} from "@store/models/models";
import Plot from "react-plotly.js";
import Card from 'react-bootstrap/Card';
import {$fileGroups, fetchFileGroupsFx} from "@store/groups/groups";
import {http} from "@server/http";
import {urls} from "@server/urls";
import Slider from "@mui/material/Slider";
import {IconButton, Typography} from "@mui/material";
import FilterListIcon from "@components/icons/FilterListIcon";
import {$fileGroupsObjects, fetchFileGroupsObjectsFx} from "@store/groupsObjects/groupsObjects";


const ApplyModel = () => {
    const [selectedFilesPredict, setSelectedFilesPredict] = useState([]);
    const [selectedGroupsPredict, setSelectedGroupsPredict] = useState([]);
    const [testSpinHidden, setTestSpinHidden] = useState(true)
    const [selectedModelId, setSelectedModelId] = useState(undefined)
    const [isExcludeFiles, setIsExcludeFiles] = useState(false)
    const [isUseGroups, setIsUseGroups] = useState(false)

    const [paramVu, setParamVu] = useState(1.1);
    const [paramVd, setParamVd] = useState(1.1);

    const [orderBy, setOrderBy] = useState('uploaded_at');
    const [selectedModelType, setSelectedModelType] = useState('prediction')

    const [predictResultTotal, setPredictResultTotal] = useState({});
    const [predictResultMetrics, setPredictResultMetrics] = useState({});

    const [pdfGenerateSpinHidden, setPdfGenerateSpinHidden] = useState(true);


    const filesOptions = useStore($datasetFiles);
    const modelOptions = useStore($models);
    const modelsObjects = useStore($modelsObjects);
    const fileGroupsOptions = useStore($fileGroups);
    const groupsObjects = useStore($fileGroupsObjects);

    useEffect(() => {
        fetchDatasetFilesFx();
        fetchModelsFx();
        fetchModelsObjectsFx();
        fetchFileGroupsFx();
        fetchFileGroupsObjectsFx();
        setIsExcludeFiles(false);
    }, [])


    const printDocument = async() => {
        toast.info('Подготовка отчета ...')
        setPdfGenerateSpinHidden(false)

        await new Promise((resolve) => setTimeout(resolve, 200));
        const input: any = document.getElementById('results');
        const pdf = new jsPDF('p', 'pt', 'a4');
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        const imgData = await html2canvas(input, {height: height*4, width: width*2.6});

        const imgWidth = 600;
        const imgHeight = imgData.height * imgWidth / imgData.width;
        let position = 0;
        pdf.addImage(imgData.toDataURL('image/png'), 'PNG', 5, position, imgWidth, imgHeight);

        pdf.save(`report_${new Date().toLocaleString()}.pdf`);
        setPdfGenerateSpinHidden(true);
    }

    const sortFiles = async(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        let setOrder = orderBy === 'uploaded_at' ? 'uploaded_at desc' : 'uploaded_at'
        fetchDatasetFilesFx({orderBy: setOrder});
        setOrderBy(setOrder);
    }


    const downloadStatisticsFile = async (e: any) => {
        const input: any = document.getElementById('results-metrics');
        const pdf = new jsPDF('p', 'pt', 'a4');
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        const imgData = await html2canvas(input, {height: height*3, width: width*3});

        const imgWidth = 500;
        const imgHeight = imgData.height * imgWidth / imgData.width;
        let position = 10;
        pdf.addImage(imgData.toDataURL('image/png'), 'PNG', 10, position, imgWidth, imgHeight);

        pdf.save(`statistics_${new Date().toLocaleString()}.pdf`);
    }

    const downloadIntervalsFile = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const response = await http.get(urls.resultIntervalsFile(), {responseType: 'blob'});
        const blob = new Blob([response.data], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `files-${selectedFilesPredict}__${new Date().toLocaleString()}.xlsx`
        link.click();
    }


    const Control = ({ children, ...props }: ControlProps<any>) => {

        return (
            <components.Control {...props}>
                <div title={'Cортировать по дате'} onMouseDown={sortFiles} className={'ms-2'}><FilterListIcon/></div>
                {children}
            </components.Control>
        );
    };

    const predict = async() => {
        setPredictResultTotal({});
        setPredictResultMetrics({});
        setTestSpinHidden(false)

        if (selectedModelId===undefined) {
            toast.error('Модель не выбрана')
            setTestSpinHidden(true)
            return
        }

        let predictFiles = selectedFilesPredict

        if (isUseGroups) {
            console.log('GGGGG', selectedGroupsPredict)
            console.log('GGGGG', groupsObjects)
            // @ts-ignore
            let files = groupsObjects.filter((item: any) => selectedGroupsPredict.includes(item.id)).map((item: any) => item.files).flat()
            files = files.map((item: any) => item.id)
            let file_ids = files.filter((value, index, array) => array.indexOf(value) === index);
            if (files.length !== 0) {
                // @ts-ignore
                setSelectedFilesPredict(file_ids);
                // @ts-ignore
                predictFiles = file_ids;
            }
        }

        if (predictFiles.length === 0) {
            toast.error('Не выбраны данные для применения модели');
            setTestSpinHidden(true);
            return
        }

        let predictData = {
            "files_ids": predictFiles,
            "file_groups_ids": [],
            "model_params": {
                "param_vu": paramVu,
                "param_vd": paramVd,
            }
        }

        toast.info('Применение модели...')
        let historyId = 0
        await http.post(urls.predictModel(selectedModelId!), predictData)
            .then((res) => {
                if (res.status === 202) {
                    historyId = res.data.id
                    console.log('history', res.data)
                } else {
                    toast.error('Ошибка при применении модели');
                    setTestSpinHidden(true);
                }
            }
        )

        let history_status = 'pending'
        while (history_status === 'pending') {
            try {
                await http.get(urls.history(historyId))
                    .then((res) => {
                        if (res.data.status === 'success') {
                            toast.success('Применение модели завершено');
                            getResults();
                            setTestSpinHidden(true);
                            history_status = res.data.status
                        } else if (res.data.status === 'fail') {
                            toast.error('Ошибка при применении модели');
                            toast.error(res.data.detail);
                            setTestSpinHidden(true);
                            history_status = res.data.status
                        }

                        console.log('SSSS', res.data.status)
                    })
            } catch {
                history_status = 'pending'
            }

            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
    };

    const getResults = async() => {
        await http.get(urls.plotResultTotal())
            .then((res) => {
                setTimeout(() => {
                    setPredictResultTotal(JSON.parse(res.data.data))
                }, 1000)
            })
        await http.get(urls.plotResultMetrics())
            .then((res) => {
                console.log('res.data', res.data)
                setPredictResultMetrics(res.data.data)
            })
    }

  return (
    <div id={'page'} style={{backgroundColor: 'white'}}>
         <div className={'col-12 ps-3 pe-3'}>
             <Card className={'col-12'}>
                  <Card.Header style={{backgroundColor: '#f2f2f4', color: 'black'}}>Применение модели</Card.Header>
                  <Card.Body style={{backgroundColor: 'white'}}>
                    <div className={'col-5'}>
                         <Form.Label className={styles.mainText} column sm="5">{"Выбрать модель"}</Form.Label>
                         <Select
                             onChange={(o) => {
                                 setSelectedModelId(o!['value']);
                                 // @ts-ignore
                                 let excludeFiles = modelsObjects.filter(m => m['id']===o!['value'])[0]['files'].map((f: any) => f.id)
                                 isExcludeFiles? fetchDatasetFilesFx({excludeFiles: excludeFiles}) : fetchDatasetFilesFx();
                                 setSelectedModelType(modelsObjects.filter(m => m['id']===o!['value'])[0]['type']);
                             }}
                             options={modelOptions}
                         />
                     </div>

                     <Form.Group as={Row} className="mb-1" controlId={"test_data"}>
                        <Form.Label className={styles.mainText + ' mt-4 col-2'} >{"Тестовые данные"}</Form.Label>

                         <div className={'row col-3 float-end'} hidden={isExcludeFiles}>
                             <p style={{width: '85px'}} className={styles.mainText + ' col-3 mt-4 float-end'} >Файлы</p>
                             <Form.Check
                                 style={{width: '45px'}}
                                 onChange={(e) => {setIsUseGroups(!isUseGroups)}}
                                 type="switch"
                                 className={'col-1 mt-4 me-0 pe-0'}
                             />
                             <p  className={styles.mainText +' col-3 mt-4 ms-0 ps-0'}>Группы</p>
                         </div>

                         <Form.Check
                          hidden={selectedModelId===undefined}
                          className={'col-1 mt-4 me-0 pe-0'}
                          type={'checkbox'}
                          color={'#70627A'}
                          style={{width: '30px'}}
                          onChange={() => {
                              // @ts-ignore
                              let excludeFiles = modelsObjects.filter(m => m['id']===selectedModelId)[0]['files'].map((f: any) => f.id)
                              setIsExcludeFiles(!isExcludeFiles);
                              !isExcludeFiles ? fetchDatasetFilesFx({excludeFiles: excludeFiles}) : fetchDatasetFilesFx(undefined)
                          }}
                        />
                        <p hidden={selectedModelId===undefined}
                            className={styles.mainText + ' col-4 ps-0 ms-1 mt-4'} >{"Исключить файлы, использованные при обучении"}
                        </p>

                        <div className={'col-11'}>
                                <div hidden={!isUseGroups}>
                                    <Select
                                        onChange={(e) => setSelectedGroupsPredict(Array.from(e.values()).map(f => f['value']))}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={fileGroupsOptions}
                                    />
                                </div>
                                <div hidden={isUseGroups}>
                                    <Select
                                        onChange={(e) => {
                                            setPredictResultMetrics({})
                                            //@ts-ignore
                                            setSelectedFilesPredict(Array.from(e.values()).map(f => f['value']))}
                                        }
                                        closeMenuOnSelect={false}
                                        components={{ Control }}
                                        isMulti
                                        options={filesOptions}
                                    />
                                </div>
                        </div>
                     </Form.Group>
                      <Form.Label hidden={selectedModelType==='prediction'} className={styles.mainText + ' mt-4 col-2'} >{"Параметры"}</Form.Label>
                      <div className={'row mt-5'} hidden={selectedModelType==='prediction'}>
                          <div className={'col-2'}>
                              <Typography gutterBottom>Vu</Typography>
                              <Slider
                                  getAriaLabel={() => 'Vu'}
                                  value={paramVu}
                                  onChange={(e, val) => setParamVu(Number(val)) }
                                  valueLabelDisplay="on"
                                  step={0.1}
                                  min={0.5}
                                  max={2.5}
                              />
                          </div>
                          <div className={'col-2'}>
                              <Typography gutterBottom>Vd</Typography>
                              <Slider
                                  getAriaLabel={() => 'Vd'}
                                  value={paramVd}
                                  onChange={(e, val) => setParamVd(Number(val)) }
                                  valueLabelDisplay="on"
                                  step={0.1}
                                  min={0.5}
                                  max={2.5}
                              />
                          </div>
                      </div>

                    <Button onClick={() => predict()} className={styles.mainButton + ' mt-2'}>
                        Применить
                        <Spinner
                            hidden={testSpinHidden}
                            className={'ms-2'}
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Button>

                  </Card.Body>
            </Card>

            <Card id={'results'} className={'col-12 mt-3'}>
                   <Card.Header style={{backgroundColor: '#f2f2f4', color: 'black'}}>Результаты расчета</Card.Header>
                   <Card.Body style={{backgroundColor: 'white'}} hidden={Object.keys(predictResultMetrics).length === 0}>
                      <div className={'row col-12'}>
                         <div className={'col-6 mt-1'}>
                             <div className={'col-12 mt-3 p-0'}>
                                 {Object.keys(predictResultTotal).length === 0 ? '' :
                                     <div>
                                         {/*@ts-ignore*/}
                                         <Plot style={{width: '200%'}} data={predictResultTotal['data']} layout={predictResultTotal['layout']}/>
                                         <br/>
                                     </div>
                                 }
                             </div>

                             <div hidden={!pdfGenerateSpinHidden} className={'mt-3'}>
                                 <Button
                                     onClick={downloadIntervalsFile}
                                     className={styles.mainButton}>
                                     Сохранить файл с результатами
                                 </Button>
                                 <Button
                                     onClick={downloadStatisticsFile}
                                     className={styles.mainButton + ' ms-2'}>
                                     Сохранить статистику
                                 </Button>
                                 <Button className={styles.mainButton + ' ms-2'} onClick={printDocument}>
                                     Сохранить отчет
                                     <Spinner
                                         hidden={pdfGenerateSpinHidden}
                                         className={'ms-2'}
                                         as="span"
                                         animation="border"
                                         size="sm"
                                         role="status"
                                         aria-hidden="true"
                                     />
                                 </Button>
                             </div>

                         </div>
                         <div className={'col-12 mt-4'}>
                             <div className={''}>
                                 {Object.keys(predictResultMetrics).length === 0 ? '' :
                                     <div className={styles.mainText}>
                                         {/*@ts-ignore*/}
                                         {filesOptions.filter(f => selectedFilesPredict.includes(f.value)).map(fi => fi.label).map(fileRes =>
                                             <FileResult
                                                 key={fileRes}
                                                 fileId = {selectedFilesPredict[0]}
                                                 name = {fileRes}
                                                 pdfGenerateSpinHidden={pdfGenerateSpinHidden}
                                                 table_data = {''}
                                                 modelType={selectedModelType}
                                             />
                                         )}
                                     </div>
                                 }
                             </div>
                         </div>

                          <div id={'results-metrics'} className={'mt-3'} hidden={!pdfGenerateSpinHidden}>
                              <h3>Статистика</h3>
                              {Object.keys(predictResultMetrics).map(k =>
                                  <div key={k} className={'row'}>
                                      <h6 className={'col-10'}>{k}</h6>
                                      {/*@ts-ignore*/}
                                      <h6 className={'col-2'}>{predictResultMetrics[k]}</h6><br/>
                                  </div>
                              )}
                          </div>

                      </div>
                   </Card.Body>
            </Card>
        </div>
    </div>

  );
};

export default ApplyModel;
