import React, {useEffect, useState} from 'react';
import styles from './ModelsWindow.module.scss';
import {useStore} from "effector-react";
import {$models, $modelsObjects, fetchModelsFx, fetchModelsObjectsFx} from "@store/models/models";
import {Card, Image} from "react-bootstrap";
import ModelDetailWindow from "@components/modelDetailWindow/ModelDetailWindow";
import {FolderIcon, ModelIcon, PredictionTabIcon} from "@components/icons_img";


const ModelsWindow = () => {
    const [showModelDetailWindow, setShowModelDetailWindow] = useState(false);
    const [selectedModelData, setSelectedModelData] = useState(
        {"title": "", "uplodaded_at": "", "files": []}
    );

    const modelOptions = useStore($models);
    const modelObjects = useStore($modelsObjects);

    console.log('modelObjects', modelObjects);

    useEffect(() => {
        fetchModelsFx();
        fetchModelsObjectsFx();
    }, [])


    return (
        <div>
           <div>
               <h2 className={styles.mainText}>Модели</h2>
                <div className={'row pe-0 me-0 mb-3 col-12'}>
                   {modelObjects.map((m: any) =>
                       <div className={'col-3 mb-3'}>
                            <Card
                                style={{backgroundColor: '#f2f2f4', height: '80px', cursor: 'pointer'}}
                                className={'col-12 mb-1'}
                                key={m!['id']}
                                onClick={() => {
                                    setShowModelDetailWindow(true);
                                    setSelectedModelData(m!);
                                }}
                            >
                                <Card.Body className={'row'}>
                                    <div className={'row'}>
                                        <div className={'col-1 '}>  <Image width={'30'} height={'30'} src={ModelIcon} className={'me-4'}/></div>
                                        <h5 className={styles.mainText + ' col-8 ms-2'}>{m!['title']}</h5>
                                    </div>
                                    <div className={'row col-12'}>
                                        <p className={'text-muted text-sm col-5'}>Тип: {m['type'] === 'prediction' ? 'model1' : 'model2'}</p>
                                        {m['score'] === '{}' || m['score'] === undefined ?  <p className={'text-muted text-sm col-6'}>Accuracy: 50</p>:
                                            <p className={'text-muted text-sm col-6'}>Accuracy: {String(JSON.parse(m.score).anomaly_detector)}</p>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                       </div>
                   )}
                </div>
           </div>
            <ModelDetailWindow
                modelData={selectedModelData}
                show={showModelDetailWindow}
                onHide={() => setShowModelDetailWindow(false)}
            />
        </div>
    );
};

export default ModelsWindow;
