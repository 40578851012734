import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import styles from './ModelDetailWindow.module.scss'
import {useStore} from "effector-react";
import {$models, fetchModelsFx, fetchModelsObjectsFx} from "@store/models/models";
import {Button, Card, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {http} from "@server/http";
import {urls} from "@server/urls";
import Confirm from "@components/confirmModal/ConfirmModal";
import Plot from "react-plotly.js";
import {fetchDatasetFilesFx} from "@store/filesObjects/filesObjects";
import Select from "react-select";


interface ModelDetailWindowInterface {
    show: boolean,
    onHide: any,
    modelData: any,
}


const ModelDetailWindow: FC<ModelDetailWindowInterface> = ({show, onHide, modelData}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showMetrics, setShowMetrics] = useState(false);

    const score = modelData['score'] !== undefined && modelData['score'] !== '{}' ? JSON.parse(modelData['score']) : {
        "anomaly_detector": 50,
        "type_detector": 50,
        "index_detector": 50
    }

    const deleteModel = async(id: number, onHide: any) => {
        try {
            await http.delete(urls.model(id))
                .then(res => {
                    if (res.status === 204) {
                        fetchModelsFx();
                        fetchModelsObjectsFx();
                        toast.success('Модель удалена')
                        onHide();
                    } else {
                        toast.error('Невозможно удалить модель')
                    }
                })
        } catch {
            toast.error('Невозможно удалить модель')
        }

    };

    return (
        <div>
            <Modal size="lg" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.mainText}>{modelData['title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={'row mt-2'}><h5 className={styles.mainText + ' col-3'}>Тип:</h5><h5 className={'col text-muted'}>{modelData.type === 'prediction' ? 'model1' : 'model2'}</h5></div>
                    <div className={'row mt-2'}><h5 className={styles.mainText + ' col-3'}>Дата создания: </h5><h5 className={'col text-muted'}>{modelData.uploaded_at}</h5></div>
                    <div className={'row mt-2'}><h5 className={styles.mainText + ' col-3'}>Владелец: </h5><h5 className={'col text-muted'}>{modelData.owner !== undefined ? modelData.owner.first_name : '' }</h5></div>
                    <div className={'row mt-2'}><h5 className={styles.mainText + ' col-3'}>Accuracy: </h5><h5 className={'col text-muted'}>{String(score.anomaly_detector)}</h5></div>
                    <div className={'row mt-2'}>
                        <h5 className={styles.mainText + ' col-3'}>Файлы: </h5>
                        <Select
                            className={'col'}
                            isDisabled={true}
                            isMulti={true}
                            options={modelData.files.map((f: any) => Object({'value': f.title, 'label': f.title}) )}
                            value={modelData.files.map((f: any) => Object({'value': f.title, 'label': f.title}) )}
                        />
                    </div>

                    <Button
                        className={'mt-2 ms-3 float-end'}
                        variant={'danger'}
                        onClick={() => {setShowConfirm(true); onHide()}}
                    >
                        Удалить модель
                    </Button>

                    <Button
                        className={'mt-2 float-end'}
                        onClick={() => {setShowMetrics(true); onHide()}}
                    >
                        Метрики
                    </Button>

                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showMetrics} onHide={() => setShowMetrics(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.mainText}>Метрики</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>anomaly_detector: {score.anomaly_detector}</h5>
                    <h5>type_detector: {score.type_detector}</h5>
                    <h5>index_detector: {score.index_detector}</h5>
                </Modal.Body>
            </Modal>

            <Confirm
                onClick={() => deleteModel(modelData.id, onHide)}
                show={showConfirm}
                text={'Удалить модель?'}
                onClose={() => setShowConfirm(false)}
            />
        </div>
    );
};

export default ModelDetailWindow;
