import ExpandDownIcon from './expand-down.png';
import ExpandUpIcon from './expand-up.png';
import Search from './search.png';
import UploadFileIcon from './upload-file.png';
import UploadFileDarkIcon from './upload-file-dark.png';
import ExcelIcon from './excel.png';
import CSVIcon from './csv.png';
import PredictionTabIcon from './prediction-tab-icon.png';
import TrainingTabIcon from './training-tab-icon.png';
import FilesTabIcon from './files-tab-icon.png';
import ModelIcon from './model.png';
import FolderIcon from './folder.png';
import AddFolderIcon from './folder-plus.png';
import AddFileIcon from './folder-move.png';
import DotsIcon from './dots.png';
import EyeIcon from './eye.png';
import KeyIcon from './key.png';
import ArrowLeftIcon from './arrow-left.png';
import ProfileIcon from './profile.png';
import HistoryIcon from './history.png';

export {
    ExpandDownIcon,
    ExpandUpIcon,
    Search,
    UploadFileIcon,
    UploadFileDarkIcon,
    ExcelIcon,
    CSVIcon,
    PredictionTabIcon,
    TrainingTabIcon,
    FilesTabIcon,
    ModelIcon,
    FolderIcon,
    AddFolderIcon,
    AddFileIcon,
    DotsIcon,
    EyeIcon,
    KeyIcon,
    ProfileIcon,
    ArrowLeftIcon,
    HistoryIcon,
};
