import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import styles from './TrainModel.module.scss';
import {toast} from "react-toastify";
import {Alert, Button, Fade, Image} from "react-bootstrap";
import {API_URL} from "@src/http";
import {$datasetFiles, fetchDatasetFilesFx} from "@store/filesObjects/filesObjects";
import {useStore} from "effector-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner';
import {$models, $modelsObjects, fetchModelsFx, fetchModelsObjectsFx} from "@store/models/models";
import Card from 'react-bootstrap/Card';
import {$fileGroups, fetchFileGroupsFx} from "@store/groups/groups";
import ModelsWindow from "@components/modelsWindow/ModelsWindow";
import {http} from "@server/http";
import {urls} from "@server/urls";



const TrainModel = () => {
    const [selectedFilesTrain, setSelectedFilesTrain] = useState([]);
    const [selectedGroupsTrain, setSelectedGroupsTrain] = useState([]);
    const [resData, setResData] = useState(Object)
    const [trainSpinHidden, setTrainSpinHidden] = useState(true)
    const [selectAll, setSelectAll] = useState(false)
    const [dist, setDist] = useState(false);

    const [validFiles, setValidFiles] = useState([]);
    const [badFiles, setBadFiles] = useState([]);
    const [delFiles, setDelFiles] = useState([]);
    const [validationRunning, setValidationRunning] = useState(false)
    const [selectedModelType, setSelectedModelType] = useState('prediction')
    const [modelName, setModelName] = useState('Модель1')
    const [isUseGroups, setIsUseGroups] = useState(true)
    const [modelTrained, setModelTrained] = useState(false)
    const [cleanData, setCleanData] = useState(false)

    const fileGroupsOptions = useStore($fileGroups);
    const modelsObjects = useStore($modelsObjects);

    useEffect(() => {
        setCleanData(false);
        setModelTrained(false)
        fetchDatasetFilesFx();
        fetchModelsFx();
        fetchFileGroupsFx();
    }, [])

    const train = async(group_id: number) => {
        if (group_id === undefined) {
            toast.error('Не выбраны данные для обучения')
            return
        }
        if (modelsObjects.map((m: any) => m.title).includes(modelName)) {
            toast.error('Модель с таким именем уже существует')
            return
        }

        setTrainSpinHidden(false)
        let group = await http.get(urls.group(group_id))
        let files_ids = group.data.files.map((f:  any) => f.id)
        let trainData = {
            "type": selectedModelType,
            "title": modelName,
            "description": "",
            "files_ids": files_ids,
            "file_groups_ids": [],
            "dist_train": true
        };

        let historyId = 0

        toast.success('Обучение модели...')

        let history_status = 'pending'

        try {
            await http.post(urls.trainModel(), trainData)
                .then((res) => {
                    console.log('TRAIN', res)
                    console.log('TRAINTRAIN', res.data.id)
                    if (res.data.id !== undefined) {
                        historyId = res.data.id
                        setResData([]);
                        setModelTrained(true)
                    } else {
                        toast.error(res.data.detail)
                    }
                })
        } catch(err: any) {
            toast.error('Невозможно обучить модель');
            toast.error(err.response.data.detail)
            console.log('eee', err)
            setTrainSpinHidden(true)
            history_status = 'fail'
        }

        while (history_status === 'pending') {
            try {
                await http.get(urls.history(historyId))
                    .then(res => {
                        console.log('history res', res)
                        if (res.data.status !== 'pending') {
                            setTrainSpinHidden(true);
                            fetchModelsFx();
                            fetchModelsObjectsFx();
                            if (res.data.status === 'success') {
                                toast.success('Модель обучена')
                            } else {
                                toast.error(res.data.status)
                            }
                            history_status = res.data.status
                        }
                })
            } catch {
                history_status = 'pending'
            }
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

    };

    const check_train_data = async(files: any[]) => {
        setValidationRunning(true)
        await (await fetch(`${API_URL}/api/check_train/`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"list_files": files})
        })).json()
            .then((res) => {
                setValidationRunning(false)
                setValidFiles(res.valid_files)
                setBadFiles(res.bad_dist)
                setDelFiles(res.del_files)
            })
    }

  return (
    <div style={{backgroundColor: 'white'}}>
        <div className={'col-12 ps-3 pe-3 mt-2'}>
            <ModelsWindow/>
            <Card>
            <Card.Header style={{backgroundColor: '#f2f2f4', color: 'black'}}>Обучение модели</Card.Header>
            <Card.Body style={{backgroundColor: 'white'}}>
                <div className={'row'}>
                    <div className={'col-3'}>
                        <Form.Label className={styles.mainText} column sm="5">{"Название модели"}</Form.Label>
                        <Form.Control onChange={(e) => setModelName(e.target.value)} defaultValue={modelName} />
                    </div>
                </div>

                <Form.Group as={Row} className="mb-1">
                    <Form.Label className={styles.mainText + ' col-4 mt-3'}>{"Тренеровочные данные (группы)"}</Form.Label>

                    <div className={'row col-3'} hidden={(selectedFilesTrain.length < 20 || isUseGroups) && !selectAll}>
                        <Form.Check
                              className={'col-1 mt-3'}
                              type={'checkbox'}
                              style={{width: '35px'}}
                              onChange={() => {setCleanData(!cleanData)}}
                        />
                        <p hidden={isUseGroups} className={styles.mainText + ' col-5 ps-0 ms-0 mt-3'} >{"Очистить данные"}</p>
                    </div>

                    <div className="row col-12">
                        <Select
                            className={'col-10'}
                            onChange={(e) => {
                                setSelectedGroupsTrain(Array.from(e.values()).map(f => f!['value']))
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                            options={fileGroupsOptions}
                        />
                    </div>

                </Form.Group>
                <div hidden={isUseGroups}>
                    {delFiles.length > 0 || badFiles.length > 0 ?<Card.Title className={styles.mainText}>Валидация файлов</Card.Title> : ''}
                    {delFiles.length > 0?
                        <div><h6 className={'ms-1 text-danger'}>Неверный формат:</h6>
                            <div style={{fontSize: '12px'}} className={styles.mainText + ' row col-11 ms-2'}>
                                {delFiles.map((f, i) => f + (delFiles.length !== i+1 ? ', ': '' ))}
                            </div>
                        </div> : ''
                    }
                    {badFiles.length > 0?
                        <div><h6 className={'ms-1 mt-2 text-danger'}>Параметр DIST неравномерный:</h6>
                            <div style={{fontSize: '12px'}} className={styles.mainText + ' row col-11 ms-2'}>
                                {badFiles.map((f, i) => f + (badFiles.length !== i+1 ? ', ': '' ))}
                            </div>
                        </div> : ''
                    }

                    {badFiles.length > 0?
                    <div className={'row'}>
                        <Form.Check
                            className={styles.mainText + ' col-1 mt-3'}
                            type={'checkbox'}
                            style={{width: '6vh'}}
                            onChange={() => setDist(!dist)}
                        />
                        <p className={styles.mainText + ' col-4 ps-0 ms-0 mt-3'} >{"Использовать DIST"}</p>
                    </div>: ''}

                    <Spinner
                        hidden={!validationRunning}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> <br/>
                </div>

                <Button
                    disabled={selectedModelType=='model2'}
                    className={styles.mainButton + ' mt-2'}
                    onClick={(e) => train(selectedGroupsTrain[0])}
                >
                    Обучить
                    <Spinner
                        hidden={trainSpinHidden}
                        className={'ms-2'}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </Button>
                <Button hidden={!(cleanData && modelTrained)} className={styles.mainButton + ' mt-2 ms-2'}  href={`${API_URL}/CACHE/result_data/clean_data/clean.zip`}>
                    Скачать очищенные данные
                </Button>
            </Card.Body>
         </Card>
        </div>
    </div>

  );
};

export default TrainModel;
