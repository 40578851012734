
import { createDomain } from 'effector';
import {API_URL} from "@src/http";
import {http} from "@server/http";
import {urls} from "@server/urls";

const FileGroupsDomain = createDomain();

export const fetchFileGroupsFx = FileGroupsDomain.createEffect(async () => {
  const res = await http.get(urls.groups())

  console.log('GGGG', res)

  let opts: any = []
  res.data.forEach((obj: any, index: any) => {
    opts.push({"value": obj.id, "label": obj.title})
  });  // формат опций для react select
  return opts
});

export const $fileGroups = FileGroupsDomain
  .createStore([])
  .on(fetchFileGroupsFx.doneData, (_, payload) => payload);

