import Slider from '@mui/material/Slider';
import React, { useState } from 'react';

const CustomSlider = ({
  value,
  onChange,
  min,
  max,
  backgroundColor,
  areaHeight = 454,
}: {
  value: number[];
  onChange: any;
  min: number;
  max: number;
  backgroundColor: string;
  areaHeight?: number;
}) => {
  const sliderStyles = {
    '& .MuiSlider-track': {
      color: backgroundColor,
      height: areaHeight,
      opacity: 0.3,
      border: 'solid rgba(45,85,245, 1) 5px',
      borderRadius: 1,
      paddingLeft: '2px',
      paddingRight: '2px',
    },
  };

  return (
    <div>
      <Slider
        sx={sliderStyles}
        value={value}
        style={{ color: 'rgb(45,85,245)' }}
        id={'sliderRange'}
        valueLabelDisplay="auto"
        onChange={onChange}
        min={min}
        max={max}
      />
    </div>
  );
};

export default CustomSlider;
