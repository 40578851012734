import {APPLYMODEL, DATA, TRAINMODEL} from '@src/routes/routes';
import React, {ChangeEvent, useCallback, useState} from 'react';
import {json, Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import styles from './Header.module.scss';
import {Button, Card, Container, Modal, Nav, Navbar, Offcanvas} from "react-bootstrap";
import AccountIcon from "@components/icons/AccountIcon";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import Dropdown from 'react-bootstrap/Dropdown';
import {fetchLoginFx} from "@store/login/login";
import {logoutAndRemoveLocalToken} from "@store/auth/authStore";


const Header = () => {
    const navigate = useNavigate();
    const logout = useCallback(async () => {
        await logoutAndRemoveLocalToken();
        navigate('/');
        window.location.reload()
    }, [navigate]);

    return (
    <div>
        <Navbar expand={true} className="mb-3 border-bottom pt-3 pb-3">
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
            <Navbar.Brand className={''}><h4>Программный модуль аналитики для выполнения расчетов магнитограмм</h4></Navbar.Brand>

            <Navbar.Offcanvas
              placement="start"
              scroll={true}
            >
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link > </Nav.Link>

                    <Dropdown className={''} drop={'start'}>
                        <DropdownToggle className={styles.dropdownToggle}>
                            <AccountIcon/>
                        </DropdownToggle>
                        <Dropdown.Menu className={''}>
                            <Dropdown.Item className={''} onClick={() => logout()}>
                                <span>Выйти</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

    </div>
  );
};

export default Header;
