import React, {FC, useEffect, useState} from 'react';
import styles from './PlotLegend.module.scss';

interface IPlotLegend {
    className?: string
}

const PlotLegend: FC<IPlotLegend> = ({className = ''}) => {

    useEffect(() => {

    }, []);



    return (
        <div className={className}>
            <div className={'row ms-5'}>
                <div className={'row col-2'}>
                    <hr style={{backgroundColor: 'rgb(179, 203, 179)'}} className={styles.LegendLine + ' col-2'}/>
                    <p className={'col-8'}>{'index [1, 11]'}</p>
                </div>
                <div className={'row col-2'}>
                    <hr style={{backgroundColor: 'rgb(238, 242, 192)'}} className={styles.LegendLine + ' col-2'}/>
                    <p className={'col-8'}>{'index [12, 20]'}</p>
                </div>
                <div className={'row col-2'}>
                    <hr style={{backgroundColor: 'rgb(240,176,179)'}} className={styles.LegendLine + ' col-2'}/>
                    <p className={'col-8'}>{'index > 20'}</p>
                </div>
                <div className={'row col-2'}>
                    <hr style={{backgroundColor: 'rgb(160, 164, 248)'}} className={styles.LegendLine + ' col-2'}/>
                    <p className={'col-8'}>{'неучтенная'}</p>
                </div>
            </div>
        </div>
    );
};

export default PlotLegend;
