export const urls = {
  authLogin: () => `/api/auth/jwt/login/`,
  authLogout: () => `/api/auth/jwt/logout/`,
  meDetail: () => `/api/users/me/`,
  user: (id: number) => `/api/users/${id}/`,

  files: (sort: string = 'uploaded_at') => `/api/files?sort=${sort}`,
  uploadFile: () => `/api/files/upload`,
  file: (id: number) => `/api/files/${id}`,
  filePlotData: (id: number) => `/api/files/${id}/plot`,

  groups: () => `/api/groups/`,
  createGroup: () => `/api/groups/create`,
  group: (id: number) => `/api/groups/${id}`,

  models: () => '/api/models/',
  trainModel: () => `/api/models/train/`,
  predictModel: (id: number) => `/api/models/${id}/predict`,
  model: (id: number) => `/api/models/${id}`,

  plotResultTotal: () => `/api/plots/total/`,
  plotResultMetrics: () => `/api/plots/metrics/`,
  plotResultPrediction: (fileName: string) => `/api/plots/prediction/${fileName}`,
  plotResultCorrelation: (fileName: string) => `/api/plots/correlation/${fileName}`,
  plotResultDetection1: (fileName: string) => `/api/plots/detection_prediction/${fileName}`,
  plotResultDetection2: (fileName: string) => `/api/plots/detection_correlation/${fileName}`,
  resultIntervalsFile: () => `/api/plots/intervals_file/`,
  resultIntervalsData: () => `/api/plots/intervals_data/`,

  setAnomaly: () => `/api/anomalies/set`,
  deleteAnomaly: () => `/api/anomalies/delete`,
  deleteAnomalyRow: () => `/api/anomalies/delete_row`,
  deleteAllAnomalies: () => `/api/anomalies/delete_all`,
  setAllAnomalies: () => `/api/anomalies/set_all`,
  setAnomalyAuto: () => `/api/anomalies/set-auto`,
  getMarkupTable: () => `/api/anomalies/get`,
  getMarkupData: () => `/api/anomalies/get_data`,
  getMarkupFile: () => `/api/anomalies/get_file`,
  uploadMarkup: () => `/api/anomalies/load-markup`,

  allHistory: () => `/api/history`,
  history: (id: number) => `/api/history/${id}`,
};
