import React from "react"
import ContentLoader from "react-content-loader"

const GroupsLoader = (props: any) => (
    <ContentLoader
        speed={0.7}
        width={1500}
        height={220}
        viewBox="0 0 1500 220"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="10" y="5" rx="5" ry="5" width="330" height="80" />
        <rect x="10" y="100" rx="5" ry="5" width="330" height="80" />
        <rect x="360" y="5" rx="5" ry="5" width="330" height="80" />
        <rect x="360" y="100" rx="5" ry="5" width="330" height="80" />
        <rect x="720" y="5" rx="5" ry="5" width="330" height="80" />
        <rect x="720" y="100" rx="5" ry="5" width="330" height="80" />
        <rect x="1070" y="5" rx="5" ry="5" width="330" height="80" />
        <rect x="1070" y="100" rx="5" ry="5" width="330" height="80" />
    </ContentLoader>
)

export default GroupsLoader