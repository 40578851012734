import { createDomain } from 'effector';
import {http} from "@server/http";
import {urls} from "@server/urls";

const AnomaliesDomain = createDomain();

export const fetchAnomaliesFx = AnomaliesDomain.createEffect(async () => {
    const res = await http.get(urls.getMarkupData())

    return res.data.data
});

export const $anomaliesData = AnomaliesDomain
    .createStore([])
    .on(fetchAnomaliesFx.doneData, (_, payload) => payload);

