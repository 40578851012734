import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './History.module.scss';

import {useStore} from "effector-react";
import {fetchModelsFx, fetchModelsObjectsFx} from "@store/models/models";
import {$historyData, fetchHistoryFx} from "@store/history/history";
import {array} from "yup";


const History = () => {
    const historyData: any = useStore($historyData)
    console.log('historyData', historyData)

    useEffect(() => {
        fetchHistoryFx()
    }, [])

    return (
      <div>
          <h2 className={'ms-3'}>История</h2>
          <div className={'mt-3 ms-3'}>
              <div className={'row'}>
                  <div className={'col-2'}><h5>Дата</h5></div>
                  <div className={'col-2'}><h5>Операция</h5></div>
                  <div className={'col-2'}><h5>Пользователь</h5></div>
                  <div className={'col-2'}><h5>Статус</h5></div>
              </div>
              {historyData.map((row: any) => {
                  return (
                      <div className={'row'} title={row.detail} style={{cursor: 'pointer'}}>
                          <div className={'col-2'}>{row.created_at}</div>
                          <div className={'col-2'}>{row.operation}</div>
                          <div className={'col-2'}>{'admin'}</div>
                          <div className={'col-2'}>
                              <p className={`${row.status === 'success' ? 'text-success' : 'text-danger'}`}>
                                  {row.status}
                              </p>
                          </div>
                      </div>
                  )
              })
              }
          </div>
      </div>
    );
};

export default History;
