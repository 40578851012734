import React, {useEffect, useState} from 'react';
import FileDropdown from "@components/fileDropdown/FileDropdown";
import SortDefault from "@components/icons/SortDefault";
import {fetchFilesObjectsFx} from "@store/filesObjects/filesObjects";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {http} from "@server/http";
import {urls} from "@server/urls";
import {fetchFileGroupsObjectsFx} from "@store/groupsObjects/groupsObjects";
import {toast} from "react-toastify";

interface IFileData {
    title: string;
    uploaded_at: string;
    count_anomalies: string;
    group_names: string[]
    id: number;
}

const FilesTable = ({files, loadFileData}: {
    files: IFileData[];
    loadFileData: () => void;
}) => {
    const [orderByUp, setOrderByUp] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<number[]>([])


    const deleteFiles = () => {
        const errors: string[] = []
        const success: string[] = []
        selectedFiles.forEach(async(id: number) => {
            try{
                await http
                    .delete(urls.file(id), {})
                    .then(res => {
                        if (success.length === 0) {
                            toast.success('Файлы удалены')
                        }
                        success.push(res.data.detail)
                    })
            } catch(e: any) {
                if (errors.length === 0) {
                    toast.error('Возникла ошибка при удалении файла')
                    toast.error(e.response.data.detail)
                }
                errors.push(e.response.data.detail)
                // if (errors.length === selectedFiles.length) {
                // toast.error('Возникла ошибка при удалении файла')
                // toast.error(e.response.data.detail)

            }
            fetchFilesObjectsFx();
        })
        setSelectedFiles([]);
        }



    const mainCheckboxChange = (e: any) => {
        if (e.target.checked) {
            setSelectedFiles(files.map((file: IFileData) => file.id))
        } else {
            setSelectedFiles([])
        }
    }

    const selectFileChange = (e: any, fileId: number) => {
        if (e.target.checked) {
            setSelectedFiles([...selectedFiles, fileId])
        } else {
            setSelectedFiles(selectedFiles.filter((id: number) => id !== fileId))
        }
        console.log(selectedFiles)
    }

    return (
        <div>
            <div className={'row mb-3 pe-3'} hidden={selectedFiles.length === 0}>
                <div className={'col-9'}></div>
                <Button className={'col-3'} onClick={deleteFiles} variant="danger" >Удалить выбранные файлы</Button>
            </div>
        <div className={'col-12'} style={{border: '1px solid #f1f2f3', borderRadius: '6px'}}>
            <div className={'row ms-0 me-0'} style={{backgroundColor: '#f1f2f3', color: 'black', height: '43px'}}>
                <div className={'col-3 mt-2'} style={{cursor: 'pointer'}} onClick={() => {
                    fetchFilesObjectsFx({ orderBy: orderByUp? 'title desc' : 'title'});
                    setOrderByUp(!orderByUp);
                }}><h5>Файл <SortDefault /></h5> </div>
                <div className={'col-2 mt-2'} style={{cursor: 'pointer'}}><h5>Загрузил <SortDefault /></h5></div>
                <div className={'col-2 mt-2'} style={{cursor: 'pointer'}} onClick={() => {
                    fetchFilesObjectsFx({ orderBy: orderByUp? 'uploaded_at desc' : 'uploaded_at'});
                    setOrderByUp(!orderByUp);
                    }}>
                    <h5>Дата добавления <SortDefault /></h5>
                </div>
                <div className={'col-2 mt-2'} style={{cursor: 'pointer'}} onClick={() => {
                    fetchFilesObjectsFx({ orderBy: orderByUp? 'count_anomalies desc' : 'count_anomalies'});
                    setOrderByUp(!orderByUp);
                }}><h5>Кол-во аномалий <SortDefault /></h5></div>
                <div className={'col-2 mt-2'} style={{cursor: 'pointer'}} onClick={() => {
                    fetchFilesObjectsFx({ orderBy: orderByUp? 'group_names desc' : 'group_names'});
                    setOrderByUp(!orderByUp);
                }}><h5>Группы <SortDefault /></h5></div>
                <div className={'col-1 mt-2'}>
                    <div className={'row'}>
                        <div className={'col-7'}>&nbsp;</div>
                        <Form.Check
                            className={'col-5 ms-0 me-0 pe-0 ps-4'}
                            onChange={mainCheckboxChange}
                        />
                    </div>
                </div>
            </div>

            {files.map((f: any, i: number) =>
                <div
                    key={f['id']}
                    style={{borderColor: 'white', backgroundColor: `${i % 2 === 0 ? 'white' : '#f2f2f3'}`} }
                    className={'pb-2 pt-2 ms-0 me-0 row border-bottom border-grey'}
                >
                    <div className={'col-3'}>{f.title}</div>
                    <div className={'col-2'}>admin</div>
                    <div className={'col-2'}>{f.uploaded_at}</div>
                    <div className={'col-2'}>{f.count_anomalies}</div>
                    <div className={'col-2'}
                         style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                        {String(f.group_names)}
                    </div>
                    <div className={'col-1 ps-5 me-0 pe-0'}>
                        <div className={'row'}>
                            <div className={'col-6 '}><FileDropdown file_id={f.id} /></div>
                            <Form.Check
                                onChange={(e: any) => selectFileChange(e, f.id)}
                                className={'col-6 me-0 pe-0'}
                                checked={selectedFiles.includes(f.id)}

                            />
                        </div>
                    </div>

                </div>
            )}

        </div>
        </div>
    );
};

export default FilesTable;
