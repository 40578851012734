import { createDomain } from 'effector';
import {http} from "@server/http";
import {urls} from "@server/urls";

const HistoryDomain = createDomain();

export const fetchHistoryFx = HistoryDomain.createEffect(async () => {
    const res = await http.get(urls.allHistory())
    console.log('rrrr', res)
    return res.data
});

export const $historyData = HistoryDomain
    .createStore([])
    .on(fetchHistoryFx.doneData, (_, payload) => payload);

