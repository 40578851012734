// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlotLegend_LegendLine__qmywI {\n  margin-top: 7px;\n  height: 10px !important;\n  border: none;\n  opacity: 1;\n}", "",{"version":3,"sources":["webpack://./src/components/plotLegend/PlotLegend.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,uBAAA;EACA,YAAA;EACA,UAAA;AACF","sourcesContent":[".LegendLine {\n  margin-top: 7px;\n  height: 10px!important;\n  border: none;\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LegendLine": "PlotLegend_LegendLine__qmywI"
};
export default ___CSS_LOADER_EXPORT___;
