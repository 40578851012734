import {useState} from "react";
import {API_URL} from "@src/http";
import styles from './LoadingSpinner.module.scss';


const LoadingSpinner = () => {

    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.loadingSpinner}>
            </div>
        </div>
    )

}
export default LoadingSpinner;