import React, {useState} from 'react';
import {API_URL} from "@src/http";
import styles from './Menu.module.scss';
import {APPLYMODEL, DATA, HISTORY, TRAINMODEL} from "@src/routes/routes";
import LogoIcon from "@components/icons/LogoIcon";
import {Image, Nav, Navbar} from 'react-bootstrap';
import MoreHorizontal from "@components/icons/MoreHorizontal";
import {FilesTabIcon, FolderIcon, PredictionTabIcon, TrainingTabIcon, HistoryIcon} from "@components/icons_img";
import {Link, NavLink, useLocation} from 'react-router-dom';

import './Menu.scss'


// @ts-ignore
const Menu = () => {
    const location = useLocation();

    return(
        <div className={styles.menu}>
        <Navbar bg={'dark'} variant={'dark'} style={{height: '100vh'}}>
            <Nav
                variant={'pills'}
                className={'flex-column me-auto h-100 w-100 p-3'}
                defaultActiveKey={'/'}
                color={'red'}

                activeKey={location.pathname}
            >
                <Navbar.Brand className='mb-5' href='/'>
                    <div className={'d-inline-block align-top ms-1 mt-1'}><LogoIcon /></div>
                </Navbar.Brand>
                <Nav.Link as={NavLink} to={APPLYMODEL} className={styles.navLink}>
                    <Image width={'30'} height={'30'} src={PredictionTabIcon} className={'me-2'}/>
                    Прогнозирование
                </Nav.Link>

                <Nav.Link as={NavLink} to={TRAINMODEL} className={styles.navLink}>
                    <Image width={'30'} height={'30'} src={TrainingTabIcon} className={'me-2'}/>
                    Обучение
                </Nav.Link>

                <Nav.Link as={NavLink} to={DATA} className={styles.navLink}>
                    <Image width={'30'} height={'30'} src={FilesTabIcon} className={'me-2'}/>
                    Файлы
                </Nav.Link>

                <Nav.Link as={NavLink} to={HISTORY} className={styles.navLink}>
                    <Image width={'26'} height={'26'} src={HistoryIcon} className={'me-2'}/>
                    История
                </Nav.Link>
            </Nav>
        </Navbar>
        </div>
    )
}

export default Menu;