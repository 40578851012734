
import { createDomain } from 'effector';
import {API_URL} from "@src/http";
import {http} from "@server/http";
import {urls} from "@server/urls";

const FileGroupsObjectsDomain = createDomain();

export const fetchFileGroupsObjectsFx = FileGroupsObjectsDomain.createEffect(async () => {
  // const res = await(await fetch(API_URL + '/api/file_groups/', {method: 'POST'})).json()
  const res = await http.get(urls.groups())

  return res.data
});

export const $fileGroupsObjects = FileGroupsObjectsDomain
  .createStore([])
  .on(fetchFileGroupsObjectsFx.doneData, (_, payload) => payload);

