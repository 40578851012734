import React, {useEffect, useState} from 'react';
import styles from './GroupsWindow.module.scss'
import {useStore} from "effector-react";
import {Button, Card, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {toast} from "react-toastify";
import Select from "react-select";
import {$datasetFiles, fetchDatasetFilesFx} from "@store/filesObjects/filesObjects";
import {$fileGroupsObjects, fetchFileGroupsObjectsFx} from "@store/groupsObjects/groupsObjects";
import {fetchFilesObjectsFx} from "@store/filesObjects/filesObjects";
import {http} from "@server/http";
import {urls} from "@server/urls";
import GroupCard from "@components/groupCard/GroupCard";
import GroupsLoader from "@components/loaders/groupsLoader/GroupsLoader";



const GroupsWindow = () => {
    const [createGroupShow, setCreateGroupShow] = useState(false);
    const [groupName, setGroupName] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([])

    const groupObjects = useStore($fileGroupsObjects);
    const filesOptions = useStore($datasetFiles);

    useEffect(() => {
        fetchDatasetFilesFx();
        fetchFileGroupsObjectsFx();
    }, []);

    const createFileGroup = async() => {
        try{
            await http
                .post(urls.createGroup(), {"title": groupName, "description": '', "files_ids": selectedFiles})
                .then(res => {
                    setCreateGroupShow(false);
                    fetchFileGroupsObjectsFx();
                    fetchFilesObjectsFx();
                    toast.success('Группа создана')
                })
        } catch {
            toast.error('Возникла ошибка при создании группы')
        }
    };

    return (
        <div>
            <div className={'row pe-0 me-0 mb-3 col-12'}>
                <h2 className={styles.mainText + ' col-7'}>Группы</h2>
                <div className={'col-5 me-0 pe-0'}>
                    <Button className={styles.mainButton + ' col-6 mt-2 float-end'} onClick={() => setCreateGroupShow(true)}>Создать группу</Button>
                </div>
                {groupObjects.length === 0 ? <GroupsLoader/> :
                    groupObjects.map((g, index) =>
                        <GroupCard key={index} groupData={g} filesOptions={filesOptions}/>
                    )
                }

            </div>

            <Modal size="lg" show={createGroupShow} onHide={() => setCreateGroupShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.mainText}>Создать группу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className={styles.mainText + ' mt-2'}>Название</h5>
                    <Form.Group controlId={'file'} className="mb-1">
                        <Form.Control onChange={(e) => setGroupName(e.target.value)} size="sm" type="text" />
                    </Form.Group>

                    <h5 className={styles.mainText + ' mt-2'}>Файлы</h5>
                    <Select
                        onChange={(e) => setSelectedFiles(Array.from(e.values()).map(f => f!['value']))}
                        closeMenuOnSelect={false}
                        isMulti
                        options={filesOptions}
                    />
                    <Button onClick={createFileGroup} className={styles.mainButton + ' mt-2 float-end'}>Сохранить</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GroupsWindow;
