import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './Info.module.scss';

import {useStore} from "effector-react";


const Info = () => {

  return (
      <div className={styles.wrapper}>
        <span>INFO</span>
      </div>
  );
};

export default Info;
