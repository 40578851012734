
import { createDomain } from 'effector';
import {API_URL} from "@src/http";
import {useStore} from "effector-react";

const LoginDomain = createDomain();

export const fetchLoginFx = LoginDomain.createEffect(async (data: any) => {
  if (data.logout) {
    return 'undefined'
  }

  let body = new URLSearchParams();
  body.append("username", data.username);
  body.append("password", data.password);

  const res = await(await fetch(API_URL + '/api/auth/jwt/login/', {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    body: body,
    redirect: 'follow'
  })).json()
  console.log('res', res)
  return res.access_token
});

export const $token = LoginDomain
  .createStore('undefined')
  .on(fetchLoginFx.doneData, (_, payload) => payload);


