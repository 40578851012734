
import { createDomain } from 'effector';
import {API_URL} from "@src/http";
import {http} from "@server/http";
import {urls} from "@server/urls";


interface GetFilesProps {
  orderBy?: string;
  excludeFiles?: number[];
}

const filesObjectsDomain = createDomain();

export const fetchFilesObjectsFx = filesObjectsDomain.createEffect(async (params?: GetFilesProps) => {
  const anomalies_req = await http.get(urls.getMarkupData())
  const anomalies = anomalies_req.data.data

  const res = await http.get(urls.files());

  let data = res.data
  if (params?.orderBy==='uploaded_at') {
    data = res.data.sort((a: any, b: any) => {
      // @ts-ignore
      return new Date(b.uploaded_at) - new Date(a.uploaded_at);
    });
  } else if (params?.orderBy==='uploaded_at desc') {
    data = res.data.sort((a: any, b: any) => {
      // @ts-ignore
      return new Date(a.uploaded_at) - new Date(b.uploaded_at);
    });
  } else if (params?.orderBy==='count_anomalies') {
    data = res.data.sort((a: any, b: any) => {
      return b.count_anomalies - a.count_anomalies;
    });
  } else if (params?.orderBy==='count_anomalies desc') {
    data = res.data.sort((a: any, b: any) => {
      return a.count_anomalies - b.count_anomalies;
    });
  }
  else if (params?.orderBy==='title desc') {
    data = res.data.sort((a: any, b: any) => {
      return a.title.localeCompare(b.title);
    });
  } else if (params?.orderBy==='title') {
    data = res.data.sort((a: any, b: any) => {
      return b.title.localeCompare(a.title);
    });
  } else if (params?.orderBy==='group_names') {
    data = res.data.sort((a: any, b: any) => {
      return String(a.group_names).localeCompare(String(b.group_names));
    });
  } else if (params?.orderBy==='group_names desc') {
    data = res.data.sort((a: any, b: any) => {
      return String(b.group_names).localeCompare(String(a.group_names));
    });
  }

  return data
});

export const $filesObjects = filesObjectsDomain
  .createStore([])
  .on(fetchFilesObjectsFx.doneData, (_, payload) => payload);


export const fetchDatasetFilesFx = filesObjectsDomain.createEffect(async (params?: GetFilesProps) => {
  const res = await http.get(urls.files(params?.orderBy));
  let opts: any = []
  let data = res.data
  if (params?.excludeFiles) {
      data = data.filter((item: any) => {
          return !params?.excludeFiles?.includes(item.id)
      })
  }
  data.forEach((element: any, index: any) => {
    opts.push({"value": element.id, "label": element.title})
  });  // формат опций для react select

  return opts
});

export const $datasetFiles = filesObjectsDomain
    .createStore([])
    .on(fetchDatasetFilesFx.doneData, (_, payload) => payload);

