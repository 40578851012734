import React from "react"
import ContentLoader from "react-content-loader"

const FilesLoader = (props: any) => (
    <ContentLoader
        speed={1}
        width={6000}
        height={1000}
        viewBox="0 0 6000 1000"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="16" y="0" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="50" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="100" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="150" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="200" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="250" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="300" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="350" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="400" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="450" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="500" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="550" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="600" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="650" rx="3" ry="3" width="40%" height="40" />
        <rect x="16" y="700" rx="3" ry="3" width="40%" height="40" />
    </ContentLoader>
)

export default FilesLoader