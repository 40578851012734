// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Menu_menu__vkBMv {\n  height: 100vh;\n  background: #D3CEDC;\n  position: fixed;\n  width: 28vh;\n}\n\n.Menu_mainText__ByPvS {\n  color: #70627A !important;\n}\n\n.Menu_navLink__MWE0Z {\n  color: #9f9f9f !important;\n  font-size: 18px !important;\n}\n.Menu_navLink__MWE0Z:hover {\n  color: #ffffff !important;\n}\n.Menu_navLink__MWE0Z :active {\n  background: #ee0000 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/menu/Menu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAMA;EACE,yBAAA;EACA,0BAAA;AAHF;AAKE;EACE,yBAAA;AAHJ;AAKE;EACE,8BAAA;AAHJ","sourcesContent":[".menu {\n  height: 100vh;\n  background: #D3CEDC;\n  position: fixed;\n  width: 28vh;\n}\n\n.mainText {\n  color: #70627A!important;\n}\n\n//.app .nav-pills .nav-link.active {\n//  background-color: var(--color-gray-80);\n//}\n\n.navLink {\n  color: #9f9f9f !important;\n  font-size: 18px!important;\n  //background: var(--color-gray-80)!important;\n  &:hover {\n    color: #ffffff !important;\n  }\n  & :active {\n    background: #ee0000 !important;\n  }\n}\n\n//.navLink:active {\n//  background: #10ff00 !important;\n//}\n//.navLink.active {\n//  background: #10ff00 !important;\n//}\n\n//.navLink.nav-link.active { background-color: red!important;}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "Menu_menu__vkBMv",
	"mainText": "Menu_mainText__ByPvS",
	"navLink": "Menu_navLink__MWE0Z"
};
export default ___CSS_LOADER_EXPORT___;
