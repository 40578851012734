
import React, {FC} from 'react';

import styles from './ProjectsLayout.module.scss';
import Header from "@components/header/Header";
import Menu from "@components/menu/Menu";

interface IProjectsLayout {
    children: any;
}

const ProjectsLayout: FC<IProjectsLayout> = ({children}) => {
    return (
        <div className={''}>
            <div className={' row col me-0 pe-0'}>
                <div className={'col-2 pe-0'}>
                    <Menu/>
                </div>
                <div className={'col-10 pe-1 ps-0'}>
                <Header/>
                {children}

                </div>
            </div>
        </div>
    );
};

export default ProjectsLayout;
