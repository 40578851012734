import { createDomain } from 'effector';
import {http} from "@server/http";
import {urls} from "@server/urls";

const ModelsDomain = createDomain();

export const fetchModelsFx = ModelsDomain.createEffect(async () => {
  const res = await http.get(urls.models())

  let opts:any = []
  res.data.forEach((element: any, index: any) => {
    opts.push({"value": element.id, "label": element.title})
  });  // формат опций для react select
  return opts
});

export const $models = ModelsDomain
  .createStore([])
  .on(fetchModelsFx.doneData, (_, payload) => payload);

export const fetchModelsObjectsFx = ModelsDomain.createEffect(async () => {
  const res = await http.get(urls.models())
  return res.data
});

export const $modelsObjects = ModelsDomain
    .createStore([])
    .on(fetchModelsObjectsFx.doneData, (_, payload) => payload);
