import React, { FC } from 'react';

interface IAddCircleIcon {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const AddCircleIcon: FC<IAddCircleIcon> = ({ color = '#ffffff', ...other }) => {
  return (
    <svg
      width="34"
      height="30"
      viewBox="4 4 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <mask id="mask0_1635_3195" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1635_3195)">
        <path
          d="M20.0003 27.9166C20.3614 27.9166 20.6603 27.7988 20.897 27.5633C21.1325 27.3266 21.2503 27.0277 21.2503 26.6666V21.25H26.7087C27.042 21.25 27.327 21.1316 27.5637 20.895C27.7992 20.6594 27.917 20.3611 27.917 20C27.917 19.6388 27.7992 19.34 27.5637 19.1033C27.327 18.8677 27.0281 18.75 26.667 18.75H21.2503V13.2916C21.2503 12.9583 21.1325 12.6738 20.897 12.4383C20.6603 12.2016 20.3614 12.0833 20.0003 12.0833C19.6392 12.0833 19.3409 12.2016 19.1053 12.4383C18.8687 12.6738 18.7503 12.9722 18.7503 13.3333V18.75H13.292C12.9587 18.75 12.6742 18.8677 12.4387 19.1033C12.202 19.34 12.0837 19.6388 12.0837 20C12.0837 20.3611 12.202 20.6594 12.4387 20.895C12.6742 21.1316 12.9725 21.25 13.3337 21.25H18.7503V26.7083C18.7503 27.0416 18.8687 27.3266 19.1053 27.5633C19.3409 27.7988 19.6392 27.9166 20.0003 27.9166ZM20.0003 35.8333C17.8059 35.8333 15.7437 35.4166 13.8137 34.5833C11.8825 33.75 10.2087 32.625 8.79199 31.2083C7.37533 29.7916 6.25033 28.1177 5.41699 26.1866C4.58366 24.2566 4.16699 22.1944 4.16699 20C4.16699 17.8055 4.58366 15.7427 5.41699 13.8116C6.25033 11.8816 7.37533 10.2083 8.79199 8.79163C10.2087 7.37496 11.8825 6.24996 13.8137 5.41663C15.7437 4.58329 17.8059 4.16663 20.0003 4.16663C22.1948 4.16663 24.2575 4.58329 26.1887 5.41663C28.1187 6.24996 29.792 7.37496 31.2087 8.79163C32.6253 10.2083 33.7503 11.8816 34.5837 13.8116C35.417 15.7427 35.8337 17.8055 35.8337 20C35.8337 22.1944 35.417 24.2566 34.5837 26.1866C33.7503 28.1177 32.6253 29.7916 31.2087 31.2083C29.792 32.625 28.1187 33.75 26.1887 34.5833C24.2575 35.4166 22.1948 35.8333 20.0003 35.8333ZM20.0003 33.3333C23.6948 33.3333 26.8409 32.035 29.4387 29.4383C32.0353 26.8405 33.3337 23.6944 33.3337 20C33.3337 16.3055 32.0353 13.1594 29.4387 10.5616C26.8409 7.96496 23.6948 6.66663 20.0003 6.66663C16.3059 6.66663 13.1603 7.96496 10.5637 10.5616C7.96588 13.1594 6.66699 16.3055 6.66699 20C6.66699 23.6944 7.96588 26.8405 10.5637 29.4383C13.1603 32.035 16.3059 33.3333 20.0003 33.3333Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default AddCircleIcon;
