import {Button} from 'react-bootstrap';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';


const Confirm = ({onClick, show, text, onClose,}: {
    onClick: () => void;
    show: boolean;
    text: string;
    onClose: () => void;
}) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Modal show={show} onHide={onClose}v>
                <Modal.Header closeButton >
                    <Modal.Title >{text}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button onClick={onClose}>
                        Отмена
                    </Button>
                    <Button className={'ms-2'} variant={'danger'}  onClick={() => {onClick(); onClose()}}>
                        Удалить
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Confirm;
