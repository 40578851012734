import FilesTable from '@components/filesWindow/FilesTable';
import FilesLoader from '@components/loaders/filesLoader/FilesLoader';

import { http } from '@server/http';
import { urls } from '@server/urls';

import { $anomaliesData, fetchAnomaliesFx } from '@store/anomalies/anomalies';
import { LOCAL_TOKEN_KEY } from '@store/auth/authStore';
import { $datasetFiles, fetchDatasetFilesFx } from '@store/filesObjects/filesObjects';
import { $filesObjects, fetchFilesObjectsFx } from '@store/filesObjects/filesObjects';
import { $fileGroupsObjects, fetchFileGroupsObjectsFx } from '@store/groupsObjects/groupsObjects';
import { $models } from '@store/models/models';

import { API_URL } from '@src/http';

import { useStore } from 'effector-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Container, Modal, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';

import styles from './FilesWindow.module.scss';

const FilesWindow = () => {
  const modelOptions = useStore($models);
  const files = useStore($filesObjects);
  const groupsObjects = useStore($fileGroupsObjects);
  const anomaliesData = useStore($anomaliesData);

  console.log('groupsObjects', groupsObjects);

  const [dFileShow, setDFileShow] = useState(false);
  const [showMarkup, setShowMarkup] = useState(false);
  const [file, setFile] = useState(Object);
  const [markupFile, setMarkupFile] = useState(Object);
  const [markupJson, setMarkupJson] = useState(Object);
  const [markupData, setMarkupData] = useState(Array<[]>);
  const [fileDownloading, setFileDownloading] = useState(false);
  const [showFileData, setShowFileData] = useState(false);
  const [indexM, setIndexM] = useState('1');
  const [anomalyType, setAnomalyType] = useState('ЛП');
  const [selectedFileData, setSelectedFileData] = useState(Object);
  const [showSpinner, setShowSpinner] = useState(false);
  const [rangeAnomaly, setRangeAnomaly] = useState(Array<any>);
  const [orderBy, setOrderBy] = useState('uploaded_at desc');
  const [selectedFileId, setSelectedFileId] = useState<number>();
  const [uploadMarkupShow, setUploadMarkupShow] = useState(false);

  useEffect(() => {
    fetchFilesObjectsFx({ orderBy: orderBy });
    fetchDatasetFilesFx();
    fetchAnomaliesFx();
  }, []);

  const loadFileData = async (file_id: number) => {
    setSelectedFileId(file_id);
    setSelectedFileData({});
    setShowFileData(true);
    console.log('loadFileData');
    try {
      const res = await http.get(urls.filePlotData(file_id));
      setSelectedFileData(JSON.parse(res.data));
    } catch (e: any) {
      toast.error(e.message);
      console.log(e);
      setShowFileData(false);
    }
  };

  const uploadMarkup = async () => {
    try {
      let fileData = new FormData();
      if (markupFile.name === undefined) {
        toast.error('Файл не выбран');
        return;
      }
      fileData.append('file', markupFile, markupFile.name);
      await http.post(urls.uploadMarkup(), fileData).then((res) => {
        if (res.status === 204) {
          toast.success('Разметка обновлена');
          setUploadMarkupShow(false);
        } else {
          toast.error('Не удалось загрузить файл');
        }
      });
    } catch (error: any) {
      toast.error('Не удалось загрузить файл');
      console.log(error);
    }
  };

  const showMarkupTable = async () => {
    setShowMarkup(true);

    await http.get(urls.getMarkupData()).then((res) => {
      setMarkupData(res.data.data);
    });

    await http.get(urls.getMarkupTable()).then((res) => {
      setMarkupJson(res.data.data);
    });
  };

  const sendFile = async () => {
    const token = localStorage.getItem(LOCAL_TOKEN_KEY);
    setFileDownloading(true);
    let fileData = new FormData();
    if (file.name === undefined) {
      toast.error('Файл не выбран');
      setFileDownloading(false);
      return;
    } else {
      fileData.append('files', file, file.name);
    }

    try {
      await (
        await fetch(`${API_URL}${urls.uploadFile()}`, {
          method: 'POST',
          body: fileData,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
      )
        .json()
        .then((res) => {
          if (res.status === 'ok') {
            toast.success('Файл загружен');
            setDFileShow(false);
            fetchDatasetFilesFx();
            fetchFilesObjectsFx({ orderBy: orderBy });
            setFileDownloading(false);
          } else {
            toast.error(res.detail);
          }
        });
    } catch (error: any) {
      console.log(error);
      toast.error(`Невозможно загрузить файл: ${error}`);
    }
  };

  const downloadMarkupFile = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const response = await http.get(urls.getMarkupFile(), { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `all_anomalies.xlsx`;
    link.click();
  };

  const delAnomalyRow = async (file_name: string, start: number, finish: number) => {
    let body = {
      file_name: file_name,
      range: [start, finish],
    };

    try {
      await http
        .post(urls.deleteAnomalyRow(), body)
        .then((res) => {
          if (res.status === 204) {
            toast.success('Участок с аномалией удален');
          } else {
            toast.error('Не удалось удалить участок с аномалиией');
          }
        })
        .then(() => {
          http.get(urls.getMarkupData()).then((res) => {
            console.log('ffff', res);
            setMarkupData(res.data.data);
          });
        });
    } catch (e) {
      console.log(e);
      toast.error('Не удалось удалить участок с аномалиией');
    }
  };

  return (
    <div>
      <div className={'row pe-0 me-0'}>
        <div className={'row col-7'}>
          <h2 className={styles.mainText + ' col-2'}>Файлы</h2>
        </div>
        <div className={'col-5 me-0 pe-0'}>
          <Button
            className={styles.mainButton + ' col-4 mt-2 float-end'}
            onClick={() => setUploadMarkupShow(true)}
          >
            Загрузить разметку
          </Button>
          <Button
            className={styles.mainButton + ' ms-1 me-1 col-5 mt-2 float-end'}
            onClick={() => {
              setFile({});
              setDFileShow(true);
            }}
          >
            Загрузить файлы измерений
          </Button>
          <Button className={styles.mainButton + ' col-2 mt-2 float-end'} onClick={showMarkupTable}>
            Разметка
          </Button>
        </div>
      </div>

      <div className={'mt-2 pe-3 ps-1'}>
        {files.length === 0 ? (
          <FilesLoader />
        ) : (
          <FilesTable files={files} loadFileData={() => loadFileData} />
        )}
      </div>

      <Modal size="lg" show={showMarkup} fullscreen={true} onHide={() => setShowMarkup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Разметка данных{' '}
            <Button className={'ms-2'} onClick={downloadMarkupFile}>
              Скачать
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>N</th>
                <th>Файл</th>
                <th>№ измерения (начало аномалии)</th>
                <th>№ измерения (конец аномалии)</th>
                <th>Index M</th>
                <th>Тип аномалии в исходных данных</th>
                <th>Тип аномалии для классификации</th>
                <th>Комментарий</th>
                <th>Учтенная/Неучтенная</th>
              </tr>
            </thead>
            <tbody>
              {markupData.map((row: any) => {
                return (
                  <tr>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                    <td>{row[3]}</td>
                    <td>{row[4]}</td>
                    <td>{row[5]}</td>
                    <td>{row[6]}</td>
                    <td>{row[7]}</td>
                    <td>{row[8]}</td>
                    <Button
                      className={'btn-red'}
                      onClick={() => delAnomalyRow(row[1], row[2], row[3])}
                      variant={'danger'}
                    >
                      &#9940;
                    </Button>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={dFileShow} onHide={() => setDFileShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.mainText}>Загрузка файлов</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className={styles.mainText + ' mt-2'}>Файл магнитограмм (.CSV)</h5>
          <Form.Group controlId={'file'} className="mb-1">
            <Form.Control
              onChange={(e) => {
                setFile((e.target as HTMLInputElement).files![0]);
              }}
              size="sm"
              type="file"
            />
          </Form.Group>
          <Button className={styles.mainButton + ' mt-2 float-end'} onClick={sendFile}>
            Загрузить
          </Button>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={uploadMarkupShow} onHide={() => setUploadMarkupShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.mainText}>Загрузка разметки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className={styles.mainText + ' mt-2'}>Файл с разметой (.xlsx)</h5>
          <Form.Group controlId={'file'} className="mb-1">
            <Form.Control
              onChange={(e) => {
                setMarkupFile((e.target as HTMLInputElement).files![0]);
              }}
              size="sm"
              type="file"
            />
          </Form.Group>
          <Button className={styles.mainButton + ' mt-2 float-end'} onClick={uploadMarkup}>
            Загрузить
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FilesWindow;
