import AddCircleIcon from '@components/icons/AddCircleIcon';
import CancelIcon from '@components/icons/CancelIcon';
import CloseIcon from '@components/icons/CloseIcon';

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

const SetAnomalyContext = ({
  xCoord,
  isEdit = false,
  delAnomaly,
  setAnomaly,
  closeContext,
  top = '80%',
  anomalyTypeDefault = 'ЛП',
  indexMDefault = 5,
}: {
  fileId: number;
  isEdit?: boolean;
  delAnomaly: any;
  setAnomaly: any;
  xCoord: string;
  closeContext: () => void;
  top?: string;
  anomalyTypeDefault?: string;
  indexMDefault?: number;
}) => {
  const [indexM, setIndexM] = useState(5);
  const [anomalyType, setAnomalyType] = useState('ЛП');
  useEffect(() => {
    console.log('indexMdefault', indexMDefault);
    setAnomalyType(anomalyTypeDefault);
    setIndexM(indexMDefault);
  }, [anomalyTypeDefault, indexMDefault]);

  return (
    <div
      className={'col-2 pb-2'}
      style={{
        borderRadius: '6px',
        border: '1px solid grey',
        position: 'absolute',
        background: 'rgb(248, 248, 250)',
        top: top,
        left: `${xCoord}px`,
        backgroundColor: 'white',
      }}
    >
      <Dropdown drop={'end'} className={'col-12 mt-1'}>
        <Dropdown.Toggle variant="light" className={'col-12'}>
          Тип аномалии
        </Dropdown.Toggle>

        <Dropdown.Menu className={'ms-2'}>
          {/*@ts-ignore*/}
          <Form
            className={'ms-3'}
            onChange={(e) => {
              //@ts-ignore
              setAnomalyType(e.target.value);
            }}
          >
            <Form.Check
              checked={anomalyType === 'ЛП'}
              type={'radio'}
              label={'ЛП'}
              value={'ЛП'}
              name="group1"
            />
            <Form.Check
              checked={anomalyType === 'НДС'}
              type={'radio'}
              label={'НДС'}
              value={'НДС'}
              name="group1"
            />
            <Form.Check
              checked={anomalyType === 'ЛП+НДС'}
              type={'radio'}
              label={'ЛП+НДС'}
              value={'ЛП+НДС'}
              name="group1"
            />
          </Form>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown drop={'end'} className={'col-12'}>
        <Dropdown.Toggle className={'col-12'} variant="light">
          Степень опасности
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/*Ввод значения*/}
          <Form.Control
            type="text"
            value={indexM}
            placeholder="Введите значение"
            onChange={(e) => {
              setIndexM(Number(e.target.value));
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
      <Button
        title={'Сохранить'}
        onClick={() => {
          setAnomaly(indexM, anomalyType);
        }}
        className={'ms-1 mt-2'}
      >
        <AddCircleIcon />
      </Button>
      <Button title={'Отмена'} onClick={closeContext} className={'ms-2 mt-2 btn-secondary'}>
        <CancelIcon />
      </Button>
      <Button
        hidden={!isEdit}
        title={'Удалить аномалию'}
        onClick={delAnomaly}
        className={'ms-2 mt-2 btn-danger'}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export default SetAnomalyContext;
