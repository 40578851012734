import ProjectsLayout from "@components/layout/projectsLayout/ProjectsLayout";

import {
    APPLYMODEL,
    DATA, HISTORY,
    INFO, TRAINMODEL
} from "@src/routes/routes";

import React, {useEffect, useState} from "react";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom.scss";
import "./App.scss";
import Info from "@pages/info/Info";
import Login from "@components/login/Login";
import ApplyModel from "@pages/applyModel/ApplyModel";
import TrainModel from "@pages/trainModel/TrainModel";
import Data from "@pages/data/Data";
import {$isAuth, LOCAL_TOKEN_KEY} from "@store/auth/authStore";
import History from "@pages/history/History";

function App() {
    // const isAuth = useStore($isAuth)
  const token = localStorage.getItem(LOCAL_TOKEN_KEY);
    console.log('token', token)
  if(token===null || token==='undefined') {
    return <Login />
  }

  return (
      <div className="App" style={{backgroundColor: 'white'}}>
        <ToastContainer autoClose={ 4000 } hideProgressBar position="bottom-right" closeButton={ false } />
        <Routes>
          <Route
              element={
                <ProjectsLayout>
                  <Outlet />
                </ProjectsLayout>
              }
          >
            <Route path={ APPLYMODEL } element={ <ApplyModel /> }></Route>
            <Route path={ TRAINMODEL } element={ <TrainModel /> }></Route>
            <Route path={ DATA } element={ <Data /> }></Route>
            <Route path={ INFO } element={ <Info /> }></Route>
            <Route path={ HISTORY } element={ <History /> }></Route>
          </Route>
        </Routes>
      </div>
  );
}

export default App;
