import CustomSlider from '@components/customSlider/CustomSlider';
import styles from '@components/filesWindow/FilesWindow.module.scss';
import BackIcon from '@components/icons/BackIcon';
import PlotLegend from '@components/plotLegend/PlotLegend';
import SetAnomalyContext from '@components/setAnomalyContext/SetAnomalyContext';

import { http } from '@server/http';
import { urls } from '@server/urls';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Plot from 'react-plotly.js';
import { toast } from 'react-toastify';

interface fileResInterface {
  fileId: number;
  name: string;
  pdfGenerateSpinHidden: boolean;
  table_data: Object;
  modelType: string;
}

interface IInterval {
  start: string;
  finish: string;
  type: string;
  indexM: string;
}

const FileResult: FC<fileResInterface> = ({
  fileId,
  name,
  pdfGenerateSpinHidden,
  table_data,
  modelType,
}) => {
  const [anomalyPlot, setAnomalyPlot] = useState({});
  const [sliderMax, setSliderMax] = useState(500);
  const [plot2Data, setPlot2Data] = useState({ data: [], frames: [], layout: {} });
  const [plot3Data, setPlot3Data] = useState({ data: [], frames: [], layout: {} });
  const [plot4Data, setPlot4Data] = useState({ data: [], frames: [], layout: {} });
  const [intervalsData, setIntervalsData] = useState<IInterval[]>([]); // data for post
  const [plotMaxX, setPlotMaxX] = useState(500);
  const [buttonY, setButtonY] = useState(0);

  const [plotWidth, setPlotWidth] = useState(500);
  const [rangeAnomaly, setRangeAnomaly] = useState([500, 1000]);
  const [showSelectAnomalyRange, setShowSelectAnomalyRange] = useState(false);
  const [isEditAnomaly, setIsEditAnomaly] = useState(false);
  const [selectedAnomalyData, setSelectedAnomalyData] = useState(Object);
  const [curtainsColor, setCurtainsColor] = useState('#0094FB');
  const [intervalHeight, setIntervalHeight] = useState(0);
  const [historyChange, setHistoryChange] = useState<any[]>([]);
  const [historyChangeIntervals, setHistoryChangeIntervals] = useState<any[]>([]);

  useEffect(() => {
    if (modelType === 'prediction') {
      setIntervalHeight(410);
    } else {
      setIntervalHeight(380);
    }
  }, [modelType]);

  useEffect(() => {
    http.get(urls.resultIntervalsData()).then((res) => {
      let intervals = res.data.filter((item: any) => item.file === name.slice(0, -4));
      setIntervalsData(intervals);
      setHistoryChangeIntervals([intervals]);
    });
    http.get(urls.plotResultPrediction(name.slice(0, -4))).then((res) => {
      setSliderMax(res.data.data.filter((l: any) => l.name === 'Объединненый сигнал')[0].x.length);
      setAnomalyPlot(res.data);
      setHistoryChange([res.data]);
    });
    http.get(urls.plotResultCorrelation(name.slice(0, -4))).then((res) => {
      setPlot2Data(res.data);
    });
    if (modelType === 'detection') {
      http.get(urls.plotResultDetection1(name.slice(0, -4))).then((res) => {
        setPlot3Data(res.data);
      });
      http.get(urls.plotResultDetection2(name.slice(0, -4))).then((res) => {
        setPlot4Data(res.data);
      });
    }
  }, []);

  const backStep = async () => {
    setAnomalyPlot(historyChange[historyChange.length - 2]);
    setHistoryChange((prev) => prev.slice(0, -1));
    setIntervalsData(historyChangeIntervals[historyChangeIntervals.length - 2]);
    setHistoryChangeIntervals((prev) => prev.slice(0, -1));
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setRangeAnomaly(newValue as number[]);
  };

  const delAnomaly = async (range: Array<number>) => {
    let newData = {
      ...anomalyPlot,
      // @ts-ignore
      data: anomalyPlot.data.filter((item: any) => item.x[0] !== range[0]),
    };
    // @ts-ignore
    setAnomalyPlot(newData);

    setHistoryChange([...historyChange, newData]);

    setIntervalsData((prev) => prev.filter((item: any) => item.start !== range[0]))
    setHistoryChangeIntervals([...historyChangeIntervals, intervalsData.filter((item: any) => item.start !== range[0])]);


    setRangeAnomaly([500, 1000]);
    setShowSelectAnomalyRange(false);
    setIsEditAnomaly(false);
    setCurtainsColor('#0094FB');
  };

  const selectAnomalyPlot = async (e: any) => {
    // @ts-ignore
    let plotY = e.event.srcElement.getBoundingClientRect().top + window.scrollY;
    let topOffset = 680;
    if (modelType === 'detection') {
      topOffset = 515;
    }
    setButtonY(plotY + topOffset);
    if (e.points[0].data.fill !== undefined && !isEditAnomaly) {
      setIsEditAnomaly(true);
      // @ts-ignore
      const anomalyData = anomalyPlot.data.filter(
        (item: any) => item.x[0] === e.points[0].data.x[0],
      )[0];
      setCurtainsColor(anomalyData.line.color);
      setSelectedAnomalyData(anomalyData);
      // @ts-ignore
      setAnomalyPlot({
        ...anomalyPlot,
        // @ts-ignore
        data: anomalyPlot.data.filter((item: any) => item.x[0] !== e.points[0].data.x[0]),
      });
      setTimeout(function () {
        console.log(e.points[0].data.x[0], plotMaxX, plotWidth);
        setRangeAnomaly([e.points[0].data.x[0], e.points[0].data.x[2]]);
        setShowSelectAnomalyRange(true);
      }, 200);
    }
  };

  const saveMarkup = async () => {
    toast.success('Сохранение разметки ... ');

    let data = [];
    for (const item of intervalsData) {
      let row = {
        range: [item.start, item.finish],
        type: item.type,
        index_m: item.indexM,
      };
      data.push(row);
    }
    try {
      http
        .post(urls.setAllAnomalies(), { file_id: fileId, data: data })
        .then((res) => {
          if (res.status === 204) {
            toast.success('Аномалии выбраны');
          } else {
            toast.error('Не удалось выбрать  аномалию');
          }
        })
        .catch((e: any) => {
          toast.error('Не удалось выбрать  аномалию');
          toast.error(e.response.data.detail);
        });
    } catch (e: any) {
      toast.error('Не удалось выбрать  аномалию');
      toast.error(e.response.data.detail);
    }
  };

  const setAnomaly = async (indexM: number, anomalyType: string) => {
    if (isEditAnomaly) {
      delAnomaly([selectedAnomalyData.x[0], selectedAnomalyData.x[2]]);
    }
    let color = 'green';
    if (Number(indexM) > 10 && Number(indexM) < 21) {
      color = 'yellow';
    } else if (Number(indexM) > 21) {
      color = 'red';
    }
    let x0 = rangeAnomaly[0];
    let x1 = rangeAnomaly[1];

    // @ts-ignore
    let y = anomalyPlot.data.filter((item: any) => item.name === 'Объединненый сигнал')[0].y;
    let y0 = Math.min(...y);
    let y1 = Math.max(...y);
    let insert = {
      fill: 'toself',
      fillcolor: color,
      legendgroup: 'неучтенная',
      line: {
        color: color,
        width: 0.1,
      },
      mode: 'lines',
      name: 'неучтенная',
      opacity: 0.3,
      showlegend: false,
      text: '',
      textposition: 'bottom center',
      x: [x0, x0, x1, x1, x0],
      y: [y0, y1, y1, y0, y0],
      type: 'scatter',
    };
    // @ts-ignore
    setAnomalyPlot({ ...anomalyPlot, data: [...anomalyPlot.data, insert] });
    // @ts-ignore
    setHistoryChange([...historyChange, { ...anomalyPlot, data: [...anomalyPlot.data, insert] }]);

    //set to intervalsData
    let insertIntervalsData = {
      file: name.slice(0, -4),
      start: x0,
      finish: x1,
      type: anomalyType,
      indexM: indexM,
    };
    // @ts-ignore
    setIntervalsData((prev) => prev.concat([insertIntervalsData]));
    // @ts-ignore
    setHistoryChangeIntervals([...historyChangeIntervals, intervalsData.concat([insertIntervalsData])]);
    setShowSelectAnomalyRange(false);
    setIsEditAnomaly(false);
    setCurtainsColor('#0094FB');
  };

  const closeContext = async () => {
    setShowSelectAnomalyRange(false);
    if (isEditAnomaly) {
      // @ts-ignore
      setAnomalyPlot({ ...anomalyPlot, data: [...anomalyPlot.data, selectedAnomalyData] });
      setIsEditAnomaly(false);
    }
    setCurtainsColor('#0094FB');
  };

  const onInitialized = async (figure: any, graphDiv: any) => {
    setPlotWidth(Number(graphDiv.querySelectorAll(['rect.nsewdrag'])[0].attributes.width.value));
    setPlotMaxX(figure.layout.xaxis.range[1] - figure.layout.xaxis.range[0]);
    // @ts-ignore
    setAnomalyPlot({ ...anomalyPlot, data: [...anomalyPlot.data] });
  };

  return (
    <div className={'row '}>
      <h2 className={'mt-5 ms-3'}> {name} </h2>

      <div id={'main_plot'} className={'col-12'}>
        {/*@ts-ignore*/}
        {anomalyPlot.data === undefined ? (
          ''
        ) : (
          <Plot
            //@ts-ignore
            data={anomalyPlot.data}
            //@ts-ignore
            layout={anomalyPlot.layout}
            //@ts-ignore
            frames={anomalyPlot.frames}
            style={{ width: '100%' }}
            onClick={selectAnomalyPlot}
            onInitialized={(figure, graphDiv) => onInitialized(figure, graphDiv)}
          />
        )}

        <PlotLegend className={'ms-3 mb-5'} />

        <div className={'correctAnomalies'} hidden={!pdfGenerateSpinHidden}>
          <div className={'row ps-4 col-12'}>
            <Button
              style={{ width: '6vh' }}
              hidden={historyChange.length < 2}
              onClick={backStep}
              variant={'secondary'}
              className={'col-1 ms-2 mt-4 p-0'}
              title={'Отменить последнее действие'}
            >
              <BackIcon />
            </Button>
            <Button onClick={saveMarkup} className={styles.mainButton + ' ms-2 col-2 mt-4'}>
              Сохранить разметку
            </Button>

            <Button
              onClick={(e) => {
                setIsEditAnomaly(false);
                setShowSelectAnomalyRange(true);
                let eOffset = (e.target as HTMLElement).offsetTop + 380;
                if (modelType === 'detection') {
                  eOffset = eOffset + 85;
                }
                setButtonY(eOffset);
                setRangeAnomaly([500, 1000]);
              }}
              className={styles.mainButton + ' col-2 ms-2 mt-4'}
            >
              Отметить аномалию
            </Button>

            <p className={'text-muted text-sm col-5 mt-4'}>
              * Для удаления / редактирования аномалии кликните по участку на графике
            </p>

            <div
              hidden={!showSelectAnomalyRange}
              style={{
                marginLeft: '44px',
                width: `${Number(plotWidth) + 48}px`,
                top: `${buttonY - 876}px`,
                position: 'absolute',
                zIndex: 1000,
              }}
              className={'row'}
            >
              <CustomSlider
                areaHeight={intervalHeight}
                value={rangeAnomaly}
                onChange={handleChange}
                min={0}
                max={sliderMax}
                backgroundColor={curtainsColor}
              />

              <SetAnomalyContext
                fileId={fileId}
                isEdit={isEditAnomaly}
                delAnomaly={() => delAnomaly([selectedAnomalyData.x[0], selectedAnomalyData.x[2]])}
                setAnomaly={setAnomaly}
                xCoord={`${(rangeAnomaly[1] / plotMaxX) * plotWidth + 12}`}
                closeContext={closeContext}
                top={`25px`}
                indexMDefault={
                  selectedAnomalyData.name === 'index [1,11]'
                    ? 5
                    : selectedAnomalyData.name === 'index [12,20] '
                    ? 15
                    : 25
                }
                anomalyTypeDefault={
                  selectedAnomalyData.text
                    ? selectedAnomalyData.text
                        .substring(
                          selectedAnomalyData.text.indexOf('Тип аномалии:') +
                            'Тип аномалии:'.length,
                          selectedAnomalyData.text.indexOf(
                            '<br>',
                            selectedAnomalyData.text.indexOf('Тип аномалии:') +
                              'Тип аномалии:'.length,
                          ),
                        )
                        .trim()
                    : 'ЛП'
                }
              />
            </div>
          </div>
        </div>

        <Accordion
          defaultActiveKey="0"
          className={'mt-5'}
          // hidden={!pdfGenerateSpinHidden}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Остальные графики</Accordion.Header>
            <Accordion.Body>
              <div>
                <Plot style={{ width: '100%' }} data={plot2Data.data} layout={plot2Data.layout} />
                <div hidden={modelType === 'prediction'}>
                  <Plot
                    style={{ width: '100%' }}
                    data={plot3Data.data}
                    layout={{ ...plot3Data.layout, ...{ autosize: true } }}
                  />
                  <Plot
                    style={{ width: '100%' }}
                    data={plot4Data.data}
                    layout={{ ...plot4Data.layout, ...{ autosize: true } }}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default FileResult;
