import React, { FC } from 'react';

const LogoIcon: FC = () => {
  return (
      <svg width="64" height="64" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_236_918)">
              <path d="M0 12.3077C0 5.51034 5.51034 0 12.3077 0H67.6923C74.4897 0 80 5.51034 80 12.3077V67.6923C80 74.4897 74.4897 80 67.6923 80H12.3077C5.51034 80 0 74.4897 0 67.6923V12.3077Z" fill="#0094FF"/>
              <circle cx="40.4561" cy="40.2411" r="2.36923" fill="white"/>
              <circle cx="52.3018" cy="40.2411" r="2.36923" fill="white"/>
              <circle cx="20.315" cy="40.241" r="1.77692" fill="white"/>
              <circle cx="61.7787" cy="40.2411" r="2.8366" fill="white"/>
              <circle cx="28.6031" cy="40.2342" r="2.07308" fill="white"/>
              <circle cx="40.4561" cy="28.3948" r="2.10547" fill="white"/>
              <circle cx="40.4561" cy="52.0872" r="2.10547" fill="white"/>
              <circle cx="40.4561" cy="62.7486" r="2.36923" fill="white"/>
              <circle cx="40.4561" cy="18.9178" r="2.36923" fill="white"/>
              <circle cx="33.3433" cy="47.3485" r="2.07308" fill="white"/>
              <circle cx="33.3433" cy="33.1334" r="2.07308" fill="white"/>
              <circle cx="26.2405" cy="54.4564" r="1.77692" fill="white"/>
              <circle cx="26.2405" cy="26.0257" r="1.77692" fill="white"/>
              <circle cx="47.5638" cy="33.1331" r="2.36923" fill="white"/>
              <circle cx="47.5638" cy="47.349" r="2.36923" fill="white"/>
              <circle cx="54.6717" cy="26.0243" r="3.09921" fill="white"/>
              <circle cx="54.6717" cy="54.4552" r="3.09921" fill="white"/>
          </g>
          <defs>
              <clipPath id="clip0_236_918">
                  <rect width="80" height="80" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default LogoIcon;
