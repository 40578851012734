import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {$datasetFiles, fetchDatasetFilesFx} from "@store/filesObjects/filesObjects";
import {$models, fetchModelsFx} from "@store/models/models";
import {$fileGroups, fetchFileGroupsFx} from "@store/groups/groups";
import ModelsWindow from "@components/modelsWindow/ModelsWindow";
import GroupsWindow from "@components/groupsWindow/GroupsWindow";
import FilesWindow from "@components/filesWindow/FilesWindow";


const Data = () => {
    useEffect(() => {
        fetchDatasetFilesFx();
        fetchModelsFx();
        fetchFileGroupsFx();
    }, [])

  return (
    <div className={'ms-2 ps-2 pe-2'} style={{backgroundColor: 'white'}}>
         <GroupsWindow/>
         <FilesWindow/>
    </div>
  );
};

export default Data;
