import React, {useState, useCallback} from 'react';
import {API_URL} from "@src/http";
import {Button, Card, Form} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from "react-toastify";
import {useStore} from "effector-react";
import {$token, fetchLoginFx} from "@store/login/login";
import { NavLink, useNavigate } from 'react-router-dom';
import { http } from '@server/http';
import { urls } from '@server/urls';


import {
    $urlBeforeAuth,
    clearUrlBeforeAuth,
    creatAuthTokenAndSaveLocalFx,
} from '@store/auth/authStore';

// @ts-ignore
const Login = () => {
    const url = useStore($urlBeforeAuth);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const token = useStore($token);
    const navigate = useNavigate();

    const login = async(event:any) => {
        event.preventDefault();
        fetchLoginFx({"username": username, "password": password})
        if (token === 'undefined') {
            toast.error('Неверный логин или пароль')
        }
    }

    const createTokenAndRedirect = useCallback(
        async (e: any) => {
            e.preventDefault();
            try {
                await creatAuthTokenAndSaveLocalFx({"username": username, "password": password});
                navigate('/');
                clearUrlBeforeAuth();
                window.location.reload()
            } catch (e: any) {
                toast.error('Неверный логин или пароль.');
                console.log(e);
            }
        },
        [navigate, url, username, password],
    );

  return(
             <Card style={{marginLeft: '65vh'}} className="shadow col-4 mt-5">
                 <ToastContainer autoClose={ 4000 } hideProgressBar position="bottom-right" closeButton={ false } />
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Почта
                        </Form.Label>
                        <Form.Control onChange={(e) => setUsername(e.target.value)} type="username" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" />
                      </Form.Group>
                      <div className="d-grid">
                        <Button onClick={(e) => createTokenAndRedirect(e)} type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
  )
}

export default Login;