import { LOCAL_TOKEN_KEY, setUrlBeforeAuth } from '@store/auth/authStore';
import axios from 'axios';
import {API_URL} from "@src/http";


export const http = axios.create({
  baseURL: API_URL,
  withCredentials: false,
});

http.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem(LOCAL_TOKEN_KEY);
    if (token) {
      //@ts-ignore
      config.headers!.Authorization = 'Bearer ' + token;
    }
  } catch (e) {
    console.log(e);
  }
  return config;
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error)
    return Promise.reject(error);
  },
);

const handelUnauthorizedStatus = () => {
  if (localStorage.getItem(LOCAL_TOKEN_KEY)) {
    localStorage.removeItem(LOCAL_TOKEN_KEY);
    setUrlBeforeAuth(window.location.href);
    window.location.href = '/';
  }
};
